import {
  HORSE_ARMOR_UNION,
  HORSE_AVATAR_BG,
  OPEN_RACE_TOP_FRAME,
  CLASS_FIVE,
  CLASS_FOUR,
  CLASS_FREE_STYLE,
  CLASS_ONE,
  CLASS_SEVEN,
  CLASS_SIX,
  CLASS_THREE,
  CLASS_TWO,
  HORSE_ARMOR_LEFT_RECTANGLE,
  HORSE_ARMOR_RECTANGLE
} from 'assets/images'
import styled from 'styled-components'

interface ClassTagStyledProps {
  valueText: string
}

const handleBackgroundClass = (valueText: string) => {
  if (valueText === 'Class 1') {
    return CLASS_ONE
  }

  if (valueText === 'Class 2') {
    return CLASS_TWO
  }

  if (valueText === 'Class 3') {
    return CLASS_THREE
  }

  if (valueText === 'Class 4') {
    return CLASS_FOUR
  }

  if (valueText === 'Class 5') {
    return CLASS_FIVE
  }

  if (valueText === 'Class 6') {
    return CLASS_SIX
  }

  if (valueText === 'Class 7') {
    return CLASS_SEVEN
  }

  if (valueText === 'FreeStyle') {
    return CLASS_FREE_STYLE
  }
}

const HorseItemStyled = styled.div`
  .no-data {
    font-weight: 800;
    background-color: #191d2c;
    padding: 20px;
    color: #ed842a;
    text-align: center;
    text-transform: uppercase;
  }
  .market-container {
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    .market-item {
      width: 230px;
    }

    .btn-detail-container {
      position: absolute;
      top: 45%;
      left: 25%;
      display: none;
      clip-path: polygon(0 0, calc(100% - 14px) 0, 100% 14px, 100% 100%, 14px 100%, 0% calc(100% - 14px));
      background: linear-gradient(90deg, #4ef07621 0%, #48f7ba17 100%);
      z-index: 5;
      padding: 1px;
      width: 103px;
      height: 34px;
      aspect-ratio: 100 / 42;

      .btn-detail {
        clip-path: polygon(0 0, calc(100% - 14px) 0, 100% 14px, 100% 100%, 14px 100%, 0% calc(100% - 14px));
        border: none;
        background: linear-gradient(
          178.19deg,
          rgba(78, 240, 118, 0.5) 1.64%,
          rgba(72, 247, 186, 0) 47.93%,
          rgba(78, 240, 118, 0.5) 97.84%
        );
        font-size: 16px;
        line-height: 20px;
        width: 100%;
        height: 100%;
      }
    }
    .horse-item:hover + .btn-detail-container,
    .btn-detail-container:hover {
      display: block;
    }
  }

  .top {
    padding: 4px;
    .top-frame {
      top: -1px;
      left: 0px;

      background-image: url(${OPEN_RACE_TOP_FRAME});
      background-size: 100%;
      background-repeat: no-repeat;
      width: 100%;
      height: 30px;

      ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
        height: 50px;
      }
    }
  }

  .horse-item {
    border-right: 2px solid #0d401ab5;
    border-left: 2px solid #56b56ebf;
    border-bottom: 2px solid #226634;
    position: relative;
    width: 100%;
    height: 260px;
    color: white;

    .header {
      margin: 12px;
      
      .header-horse {
        position: relative;
        width: 100%;

        .header-horse-name {
          font-size: 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 16px;

          .horse-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 150px;
          }
          .horse_gender {
            width: 30px;
          }
          .region {
            width: 20px;
            height: 20px;
          }
        }

        .horse-lending {
          font-size: 16px;
          color: #bd9f36;
          position: absolute;
          top: 20;
          width: 100%;
          display: flex;
          justify-content: space-between;

          .left {
            flex-direction: column;
            .horse-type {
              font-size: 16px;
              color: #bd9f36;
            }
          }

          .right {
            display: flex;
            flex-direction: column;
            align-items: end;
            margin-right: -5px;
            .img_new_record {
              width: 25px;
              height: auto;
              margin-top: 5px;
              margin-right: 3px;
            }
            .icon-vip {
              padding-top: 5px;
            }
            .icon_doping {
              width: 40px;
              margin-right: -5px;
            }

            .used-doping{
              width: 40px;
              position: absolute;
              right: 0;
              top: 25px;
            }
          }
        }
      }
    }
    
    .background-container {
      .background {
        background-image: url(${HORSE_AVATAR_BG});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 130px;
        top: 12%;
        width: unset;
        .avatar, .skin, .body {
          width: 150px;
          height: 150px;
          aspect-ratio: 300 / 300;
          object-fit: contain;
          object-position: center;

          ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
            width: 100%;
          }
        }
        .glass, .head {
          height: auto;
          width: 75px;
          top: 0%;
          left: 0%;
          z-index: 2;
        }
        .shoes {
          width: 150px;
          height: auto;
          left: 0%;
          bottom: 0%;
          top: unset;
          z-index: 3;
        }

        .foot {
          width: 150px;
          height: auto;
          left: 0%;
          bottom: 0%;
          top: unset;
          z-index: 4;
        }
      }
    }

    .bottom-item {
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;

      .bottom_item_winrar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 28px;
        background: url(${HORSE_ARMOR_LEFT_RECTANGLE});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;

        .item_winrar, .item_carrer {
          padding-left: 8px;
          font-size: 13px;
        }

        .item_winrar {
          width: 103px;
        }

        .item_carrer {
          flex: 1;
        }

        .item_winrar_title {
          color: #4ef076;
          height: 13px;
        }
        .item_winrar_content {
          .color__fff566 {
            color: #fff566;
          }
        }
      }

      .bottom_item_winrar:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 20px;
        left: 103px;
        background-image: url(${HORSE_ARMOR_RECTANGLE});
        background-repeat: no-repeat;
      }

      .bottom_item_class {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background: url(${HORSE_ARMOR_UNION});
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .bottom__class {
          height: 29px;
          width: 70px;
        }

        .bottom__price_lending {
          flex: 1;
          width: calc(100% - 70px);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 2px 0 4px;
          word-break: break-word;

          .price__lending_type {
            width: 65px;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 12px;
          }

          .price-lending {
            flex: 1;
            color: #5e7bea;
            font-size: 16px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .price {
              flex: 1;
              text-align: end;
            }
            .icon_kuda {
              width: 18px;
              height: 18px;
              display: block;
            }
          }
        }

        .day-time {
          color: #d6b233;
        }
      }
    }
  }
  .view-more-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16px;
    height: 80px;

    .btn-view-more {
      background: linear-gradient(
        178.19deg,
        rgb(240 241 240 / 82%) 1.64%,
        rgb(235 245 241 / 48%) 47.93%,
        rgb(251 251 251 / 63%) 97.84%
      );
      border-radius: 4px;
      height: 100%;
      width: 250px;
      font-size: 16px;
      &-text {
        margin: 16px;
        color: white;
      }
    }
    .btn-view-more:hover {
      background: linear-gradient(
        178.19deg,
        rgb(120 229 147 / 82%) 1.64%,
        rgb(52 255 184 / 48%) 47.93%,
        rgb(78 240 118 / 63%) 97.84%
      );
    }
    .btn-view-more:after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #f9fafb;
      content: '';
      position: relative;
      right: -5px;
      top: 11px;
    }
  }
`

export default HorseItemStyled

export const BClassTagStyled = styled.div<ClassTagStyledProps>`
  height: 29px;
  width: 70px;
  background-image: url(${({ valueText }) => handleBackgroundClass(valueText)});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;

  .bottom__class_lending {
    padding-left: 4px;
    font-size: 15px;
    text-transform: uppercase;
    line-height: 20px;
    color: #ffffff;
  }
`
