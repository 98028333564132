/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getListNewRecordOfHorse, getTopNewRecord } from "./action"

interface initialStateType {
  loadingNewRecord: boolean
  newRecord: any
  errorNewRecord: string
  listNewRecordOfHorse: any
}

const initialState: initialStateType = {
  loadingNewRecord: false,
  newRecord: {},
  errorNewRecord: "",
  listNewRecordOfHorse: []
}

export const newRecordSlice = createSlice({
  name: 'newRecord',
  initialState,
  reducers: {},
  extraReducers: {
    //get new record
    [getTopNewRecord.pending.type]: (state) => {
      state.loadingNewRecord = true
      state.newRecord = {}
    },

    [getTopNewRecord.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loadingNewRecord = false;
      state.errorNewRecord = "";
      state.newRecord = action.payload;
    },
    
    [getTopNewRecord.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadingNewRecord = false;
      state.errorNewRecord = action.payload;
    },

    //get List NewRecord Of Horse
    [getListNewRecordOfHorse.pending.type]: (state) => {
      state.loadingNewRecord = true
      state.listNewRecordOfHorse = []
    },

    [getListNewRecordOfHorse.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loadingNewRecord = false;
      state.errorNewRecord = "";
      state.listNewRecordOfHorse = action.payload;
    },
    
    [getListNewRecordOfHorse.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadingNewRecord = false;
      state.errorNewRecord = action.payload;
    }
  },
})

export default newRecordSlice.reducer
