/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react'

import { RankHorse, StepHorse } from 'models'
import { getPositionNumberGate, ordinalSuffixOf } from 'utils/helper'
import HorseRankBox from '../HorseRankBox'
import HorseRankListStyled from './styled'

interface HorseRankListProps {
  horses: StepHorse[]
  sortingRankHorseList: RankHorse[]
}

// const HORSE_NUMBER_IN_RACE = 12

function HorseRankList({ horses, sortingRankHorseList }: HorseRankListProps) {
  const generateRankNumber = useCallback((): number[] => {
    const rankNumberList: number[] = []
    for (let i = 1; i <= horses?.length; i++) {
      rankNumberList.push(i)
    }
    return rankNumberList
  }, [])

  const getHorseRankFromSortingList = useCallback(
    (index: number): number | undefined => {
      const sortRank = sortingRankHorseList.sort((a: any, b: any) => a.nullRace - b.nullRace)
      const horseRank = sortRank.findIndex(horse => index === horse.horseIndex)
      return horseRank === -1 ? undefined : horseRank
    },
    [sortingRankHorseList]
  )

  return (
    <HorseRankListStyled className='d-flex'>
      <div className='rank-list-container d-flex flex-column'>
        {generateRankNumber().map(rank => (
          <div key={rank} className='rank-box d-flex align-items-center'>
            <div className='rank color-white font-bold d-inline-block'>
              <div dangerouslySetInnerHTML={{ __html: ordinalSuffixOf(rank) }} />
            </div>
          </div>
        ))}
      </div>
      <div className='horse-list-container position-relative'>
        {horses.map((horse, index) => {
          const numberGate: any = getPositionNumberGate(horse?.gate)          
          return <HorseRankBox
            gate={numberGate}
            key={horse.id}
            horseName={horse.name}
            subAvatar={horse?.sub_avatar || horse?.subAvatar}
            currentRank={getHorseRankFromSortingList(numberGate - 1) ?? index}
            ownerId={horse.owner?.id}
            horse={horse}
            region={horse?.region}
          />
        })}
      </div>
    </HorseRankListStyled>
  )
}

export default HorseRankList
