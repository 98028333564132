/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppDispatch } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { encodeURI } from 'js-base64'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import { getListNewRecordOfHorse } from 'apps/redux/newRecord/action'
import { ClassTag } from 'shared'
import { NEW_RECORD } from 'assets/images'
import { NUMBER } from 'apps/constants'
import { listNewRecordOfHorseSelector } from 'apps/redux/newRecord/selector'
import { useSelector } from 'react-redux'
import { convertShortDayUnix, convertShortTimeMinus, convertShortTimeUnix } from 'utils/time'
import NewRecordDetailStyled from './styled'
import { getBackgroundRegion } from 'utils/helper'

interface MProps {
  horseId?: any
}

const raceListParams: any = {
  limit: NUMBER.FIVE,
  page: NUMBER.ONE
}

export default function NewRecordDetail({ horseId }: MProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const getListNewRecordOfHorseSelector = useSelector(listNewRecordOfHorseSelector)
  const [listNewRecord, setListNewRecord] = useState<any>([])
  const [pageCount, setPageCount] = useState(NUMBER.ZERO)
  const [currentPage, setCurrentPage] = useState(NUMBER.ZERO)

  useEffect(() => {
    if (horseId) {
      const params = { ...raceListParams, horseId }
      dispatch(getListNewRecordOfHorse(params))
    }
  }, [horseId])

  useEffect(() => {
    setListNewRecord(getListNewRecordOfHorseSelector.records || [])
    setPageCount(getListNewRecordOfHorseSelector.total_page || NUMBER.ONE)
  }, [getListNewRecordOfHorseSelector])

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected)
    const params = { ...raceListParams, horseId, page: +event.selected + NUMBER.ONE }
    dispatch(getListNewRecordOfHorse(params))
  }

  return (
    <NewRecordDetailStyled>
      <div className='title_new_record'>
        <div className='title'>{t(`${NOTIFICATION_MESSAGE}.new_record`)}:</div>
        {/* new record */}
        <div className='img_new_record'>
            <MemoizedLoadImageCommon srcImg={NEW_RECORD} />
        </div>
      </div>

      <div className='new-record-container'>
        <div className='d-flex header-horse'>
          <span className='ps-4 width-5'></span>
          <span className='ps-4 width-25'>{t(`${NOTIFICATION_MESSAGE}.raceName`)}</span>
          <span className='width-10'>{t(`${NOTIFICATION_MESSAGE}.raceCourse`)}</span>
          <span className='width-10'>{t(`${NOTIFICATION_MESSAGE}.class`)}</span>
          <span className='width-10'>{t(`${NOTIFICATION_MESSAGE}.fieldType`)}</span>
          <span className='width-10'>{t(`${NOTIFICATION_MESSAGE}.distance`)}</span>
          <span className='width-15'>{t(`${NOTIFICATION_MESSAGE}.record_race_time`)}</span>
          <span className='width-15'>{t(`${NOTIFICATION_MESSAGE}.record_date`)}</span>
        </div>
        <table className='new-record-table'>
          {listNewRecord.length ? (
            <tbody>
              {listNewRecord?.map((newRecord: any) => (
                <tr
                  key={newRecord?.race_id}
                  className='new_record_body w-100'
                  onClick={() => window.open(`/race/detail/${encodeURI(newRecord.race_id.toString())}`, '_blank')}
                >
                  <td className='width-5'>
                    <div className='img_new_record'>
                      <MemoizedLoadImageCommon srcImg={NEW_RECORD} />
                    </div>
                  </td>
                  <td className='ps-4 width-25 race_name race_bold'>
                    {newRecord?.race_name}
                  </td>
                  <td className='width-10'>
                    <span className={`${getBackgroundRegion(newRecord?.region)}`}>
                      {newRecord?.region}
                    </span>
                  </td>
                  <td className='width-10'>
                    <ClassTag text={newRecord?.racing_class} isActive={true} />
                  </td>
                  <td className='width-10 race_bold'>{newRecord?.field_type}</td>
                  <td className='width-10 race_bold'>{newRecord?.distance.toLocaleString()}m</td>
                  <td className='width-15 race_bold'>{convertShortTimeMinus(newRecord?.record_time)}</td>
                  <td className='width-15 race_bold'>
                    {newRecord?.record_date ? (`${convertShortDayUnix(newRecord?.record_date)} ${convertShortTimeUnix(newRecord?.record_date)}`) : '---'}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : ''}
        </table>
        {pageCount > NUMBER.ONE && (
          <div className='paginate'>
            <ReactPaginate
              nextLabel='>'
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel='<'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              forcePage={currentPage ? +currentPage : 0}
            />
          </div>
        )}
      </div>
    </NewRecordDetailStyled>
  )
}
