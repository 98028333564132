/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApiResponse,
} from 'models'
import axiosClient from './axiosClient'

const newRecordApi = {
  getTopNewRecord(params: any): Promise<ApiResponse<any>> {
    const url = `/horse/top-new-record`
    return axiosClient.get(url, { params })
  },

  getListNewRecordOfHorse(params: any): Promise<ApiResponse<any>> {
    const url = `/horse/${params.horseId}/new-records`
    return axiosClient.get(url, { params })
  },
}

export default newRecordApi
