/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import CurrentRecordHolderStyle from './styled'
import { AVATAR_DEFAULT } from 'assets/images'
import { shortenRaceNameV2 } from 'utils/helper'
import { convertShortTimeMinus } from 'utils/time'

interface MProps {
  currentRecordHolder?: any
  currentUser?: any
  onClickHorse: () => void
}

const CurrentRecordHolder = ({ currentRecordHolder, currentUser, onClickHorse }: MProps) => {
  const { t } = useTranslation()
  const [checkOwner, setCheckOwner] = useState(false)
  
  useEffect(() => {
    if (currentRecordHolder) {
      if (currentUser?.id === currentRecordHolder?.user_id) {
        setCheckOwner(true)
      } else {
        setCheckOwner(false)
      }
    }
  }, [currentRecordHolder])

  const handleClick = () => {
    onClickHorse()
  }  

  return (
    <CurrentRecordHolderStyle>
      <div onClick={handleClick} className='width-100 d-flex justify-content-center'>
        <div className='text_record_holder text-uppercase'>
          {t(`${NOTIFICATION_MESSAGE}.current_record_holder`)}:
        </div>
        <div className='d-flex'>
          <div className='name'>
            <div className='img'>
              <img src={currentRecordHolder?.sub_avatar || AVATAR_DEFAULT} alt='avatar' />
            </div>
            <span className={`text_holder ${checkOwner ? 'text_holder_green' : ''}`}>{shortenRaceNameV2(currentRecordHolder?.horse_name, 20)}</span>
          </div>
          <div className='left'>
            <span className='text_holder'>{convertShortTimeMinus(currentRecordHolder?.record_time)}</span>
          </div>
        </div>
      </div>
    </CurrentRecordHolderStyle>
  )
}

export default memo(CurrentRecordHolder)
