/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import raceApi from 'apis/raceApi'
import userApi from 'apis/userApi'
import { links, paths } from 'apps'
import { useSelector } from 'react-redux'
import { weatherSelector } from 'apps/redux/weather/selector'
import { CURRENCY_TYPE, NUMBER, ROLE_GUILD, STATUS, STRING, STRING_ARMOR_SKIN, STRING_BLOODLINE_CHARACTER, STRING_CATEGORY, STRING_STATUS } from 'apps/constants'
import {
  AVATAR_DEFAULT,
  CANCEL_COUNT_DOWN,
  CANCEL_COUNT_DOWN_DISABLE,
  ICON_LOCK,
  ICON_HIGHLIGHT,
  VIEW_DOPING,
  JACKPOT_ANIM,
  NEW_RECORD
} from 'assets/images'
import classNames from 'classnames'
import { setCoinUser } from 'features/Balance/coinUser.slice'
import RaceType from 'features/components/RaceType'
import { useAppDispatch, useToggle } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import _ from 'lodash'
import {
  CurrentUser,
  Gate,
  GetCurrentRaceListParams,
  GetProfit,
  GetRaceListParams,
  GetRaceListPopupParams,
  GetSpecialRace,
  GuildHorseInfo,
  GuildInfo,
  GuildMembersResponse,
  LENDING_TYPE,
  MyHorseListParams,
  Race,
  RecordRace,
  WeatherModel
} from 'models'
import { Fragment, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { useLocation, useNavigate } from 'react-router-dom'
import { Cell, Column, useTable } from 'react-table'
import { ClassTag } from 'shared'
import ButtonCustom from 'shared/ButtonCustom'
import ButtonTable from 'shared/ButtonTable'
import ToolTip from 'shared/ToolTip'
import { accessor, guild, profitColumns, specialRace } from 'utils/columns'
import {
  checkExistsElementInArray,
  convertPluralText,
  findElementInArray,
  getBackgroundRegion,
  getGuildTag,
  handleAsyncRequest,
  ordinalSuffixOf,
  shortenRaceCourseNameClone,
  shortenRaceNameV2,
  shortenUserName
} from 'utils/helper'
import { convertFullDate, convertShortDay, convertShortTime, getTimeStampCurrent } from 'utils/time'
import { getImgWeather } from 'utils/weather'
import ConfirmCancelModal from '../ConfirmCancelModal'
import CountDownTime from '../CountDownTime'
import MemoizedImageMERAH from '../MemoImage/Merah'
import MemoizedImageKUDA from '../MemoImage/Kuda'
import MemoizedImageBIRUOLD from '../MemoImage/BiruOld'
import MemoizedImageMERAHOLD from '../MemoImage/MerahOld'
import MemoizedImageKUDAOLD from '../MemoImage/KudaOld'
import VipIcon from 'features/components/VipIcon'
import ImageArmorSkin from 'features/components/ImageArmorSkin'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import { getImageBloodline, getImageGender, getImageRegion } from 'utils/getLinkImage'
import openNotification from 'utils/toast'
import RaceTableStyled from './styled'
import ModalHorseBloodline from 'features/components/ModalHorseBloodline'
import PrizeJackpotColumn from '../PrizeJackpotColumn'

interface RaceTableProps {
  data: any[]
  columns: any
  isRowClickable?: boolean
  onRegisterButtonClick?: (gateNumber: number) => void
  raisePage?: any
  params: GetRaceListParams | MyHorseListParams | GetCurrentRaceListParams
  loader?: boolean
  status?: string
  onQuickView?: (id: number) => void
  currentUser?: CurrentUser
  paramsFilterResult?: GetRaceListPopupParams
  checkLoadingPage?: boolean
  timeCancelRace?: number
  toggleIsModalConfirmCancelOpen?: (value?: boolean) => void
  isModalConfirmCancelOpen?: boolean
  totalRace?: number
  onRowClick?: (value: number) => void
  noDataText?: string
  isHorseTable?: boolean
  onClickOneCell?: (id: number) => void
  isShowVipIcon?: boolean
  disableSelectGate?: boolean
}

const handleStartIn = (cell: Cell) => {
  const slicePathName = window.location.pathname?.split('/').slice(1)[0]
  if ((cell.row.original as Race).status === STRING_STATUS.SCHEDULING) {
    if (slicePathName === paths.subDomainSpecialRace.index()) {
      return 'Starting soon'
    } else {
      return 'Scheduling'
    }
  }

  if ((cell.row.original as Race).status === STRING_STATUS.WAITING) {
    const countDownTime = +(cell.row.original as Race).start_at + NUMBER.THOUSAND - new Date().getTime()
    return <CountDownTime time={countDownTime} />
  }

  if ((cell.row.original as Race).status === STRING_STATUS.LIVE) {
    return (
      <div className='starts-in color-red font-bold d-flex align-items-center'>
        <div className='dot' /> <span className='live-text'>Live</span>
      </div>
    )
  }
  if ((cell.row.original as Race).status === STRING_STATUS.CLOSED || (cell.row.original as Race).status === STRING_STATUS.RESULT) {
    return (
      <div className='value color-white'>
        <div>CLOSED</div>
        <div>
          {convertShortDay((cell.row.original as Race).close_at)}{' '}
          {convertShortTime((cell.row.original as Race).close_at)}
        </div>
      </div>
    )
  }
}

const pushNotification = (description: string, isSuccess?: boolean) => {
  openNotification({
    message: '',
    description: description,
    className: isSuccess ? 'toast-success' : 'toast-error'
  })
}

const handleCancelCountDown = async (id: number) => {
  const [error,]: any = await handleAsyncRequest(raceApi.deleteCancelCountDown(id))
  if (error) {
    pushNotification(error?.message, false)
  }
}

const timeConversion = (duration: number) => {
  const convertNumber = (number: number) => (number === NUMBER.ZERO ? STRING.STR_00 : number > NUMBER.NINE ? number.toString() : `0${number}`)
  const portions: string[] = []

  const msInHour = NUMBER.THOUSAND * NUMBER.SIXTY * NUMBER.SIXTY
  const hours = Math.trunc(duration / msInHour)
  if (hours > NUMBER.ZERO) {
    portions.push(convertNumber(hours) + ':')
    duration = duration - hours * msInHour
  }

  const msInMinute = NUMBER.THOUSAND * NUMBER.SIXTY
  const minutes = Math.trunc(duration / msInMinute)
  portions.push(convertNumber(minutes) + ':')
  duration = duration - minutes * msInMinute

  const seconds = Math.trunc(duration / NUMBER.THOUSAND)
  portions.push(convertNumber(seconds))
  return portions.join('')
}

const handleRaceName = (cell: Cell) => {
  if (
    (cell.row.original as Race).status === STRING_STATUS.OPEN ||
    (cell.row.original as Race).status === STRING_STATUS.SCHEDULING ||
    (cell.row.original as Race).status === STRING_STATUS.WAITING ||
    (cell.row.original as Race).status === STRING_STATUS.LIVE ||
    (cell.row.original as Race).status === STRING_STATUS.CLOSED
  ) {
    return <ToolTip name={cell.value} />
  }
}

const handleDisplayTotalPrize = (cell: Cell, type: any) => {
  const { row } = cell
  let total_prize: any = 0
  if (type === "price") {
    total_prize = row.values?.total_prize
  } else {
    total_prize = row.values?.received_prize
  }
  const entry_fee_origin = (cell.row.original as Race).entry_fee
  const entry_fee_detail = row.values.entry_fee as any
  const legacyData = (cell.row.original as Race)?.legacy_data

  let getEntryFeeData = entry_fee_origin
  if (entry_fee_origin === undefined) {
    getEntryFeeData = entry_fee_detail
  } 
  
  if (legacyData) {
    if (getEntryFeeData === NUMBER.ZERO) {
      if (total_prize) {
        return (
          <div className='prize'>
            <span className='color-biru font-bold'>{cell.value}</span>{' '}
            <MemoizedImageBIRUOLD />
          </div>
        )
      } else {
        return '---'
      }
    } else {
      if (total_prize) {
        return (
          <div className='prize'>
            <span className='color-merah font-bold'>{cell.value}</span>{' '}
            <MemoizedImageMERAHOLD />
          </div>
        )
      } else {
        return <span>---</span>
      }
    }
  } else {
    if (getEntryFeeData === NUMBER.ZERO) {
      if (total_prize) {
        return (
          <div className='prize'>
            <span className='color-merah font-bold'>{cell.value}</span>{' '}
            <MemoizedImageKUDA />
          </div>
        )
      } else {
        return <span>---</span>
      }
    } else {
      if (total_prize) {
        return (
          <div className='prize'>
            <span className='color-merah font-bold'>{cell.value}</span>{' '}
            <MemoizedImageMERAH />
          </div>
        )
      } else {
        return <span>---</span>
      }
    }
  }
}

const handleDisplayTotalPrizeT = (cell: Cell) => {
  const { row } = cell
  // const { totalPrizeT } = row.values
  const entry_fee_origin = (cell.row.original as Race).entry_fee
  const entry_fee_detail = row.values.entry_fee as any
  const legacyData = (cell.row.original as Race)?.legacy_data

  let getEntryFeeData = entry_fee_origin
  if (entry_fee_origin === undefined) {
    getEntryFeeData = entry_fee_detail
  }

  const jackpot = {
    position: (cell.row.original as any)?.rank_in_race,
    jackpot: (cell.row.original as any)?.jackpot,
    jackpot_detail: (cell.row.original as any)?.jackpot_detail
  }

  if (legacyData) {
    if (getEntryFeeData === NUMBER.ZERO) {
      return <PrizeJackpotColumn
                jackpot={jackpot}
                price={cell.value}
                horseId={(row.original as any)?.detailHorse}
                raceId={(row.original as any)?.raceId}
                type="BIRUOLD"
              />
    } else {
      return <PrizeJackpotColumn
                jackpot={jackpot}
                price={cell.value}
                horseId={(row.original as any)?.detailHorse}
                raceId={(row.original as any)?.raceId}
                type="MERAHOLD"
              />
    }
  } else {
    if (getEntryFeeData === NUMBER.ZERO) {
      return <PrizeJackpotColumn
                jackpot={jackpot}
                price={cell.value}
                horseId={(row.original as any)?.detailHorse}
                raceId={(row.original as any)?.raceId}
                type="KUDA"
              />
    } else {
      return <PrizeJackpotColumn
                jackpot={jackpot}
                price={cell.value}
                horseId={(row.original as any)?.detailHorse}
                raceId={(row.original as any)?.raceId}
                type="MERAH"
              />
    }
  }
}

const handleDisplayRacePrize = (cell: Cell) => {
  const { row } = cell
  const { getEntryFee } = row.original as any
  const { race_prize } = row.values
  const legacyData = (row.original as any)?.legacy_data
  const checkJackpot = (row.original as any)?.jackpot?.jackpot_detail?.some((e: any) => e?.position === (row.original as any)?.jackpot?.position) || false
  const checkNewRecord = (row.original as any)?.record_detail?.new_record_detail?.some((e: any) => e?.horse_id === (row.original as any)?.record_detail?.horse_id) || false

  if (legacyData) {
    if (getEntryFee === NUMBER.ZERO) {
      if (race_prize > NUMBER.ZERO || checkJackpot || checkNewRecord) {
        return (
          <PrizeJackpotColumn
            jackpot={(row.original as any)?.jackpot}
            newRecord={(row.original as any)?.record_detail}
            price={cell.value}
            horseId={(row.original as any)?.detailHorse}
            raceId={(row.original as any)?.raceId}
            type="BIRUOLD"
          />
        )
      } else {
        return '---'
      }
    } else {
      if (race_prize > NUMBER.ZERO || checkJackpot || checkNewRecord) {
        return (
          <PrizeJackpotColumn
            jackpot={(row.original as any)?.jackpot}
            newRecord={(row.original as any)?.record_detail}
            price={cell.value}
            horseId={(row.original as any)?.detailHorse}
            raceId={(row.original as any)?.raceId}
            type="MERAHOLD"
          />
        )
      } else {
        return <span>---</span>
      }
    }
  } else {
    if (getEntryFee === NUMBER.ZERO) {
      if (race_prize > NUMBER.ZERO || checkJackpot || checkNewRecord) {
        return (
          <PrizeJackpotColumn
            jackpot={(row.original as any)?.jackpot}
            newRecord={(row.original as any)?.record_detail}
            price={cell.value}
            horseId={(row.original as any)?.detailHorse}
            raceId={(row.original as any)?.raceId}
            type="KUDA"
          />
        )
      } else {
        return <span>---</span>
      }
    } else {
      if (race_prize > NUMBER.ZERO || checkJackpot || checkNewRecord) {
        return (
          <PrizeJackpotColumn
            jackpot={(row.original as any)?.jackpot}
            newRecord={(row.original as any)?.record_detail}
            price={cell.value}
            horseId={(row.original as any)?.detailHorse}
            raceId={(row.original as any)?.raceId}
            type="MERAH"
          />
        )
      } else {
        return <span>---</span>
      }
    }
  }
}

const handleFieldType = (field: string) => {
  if (field === STRING.TURF) {
    return <span className='font-bold color-turf-field'>{field}</span>
  } else {
    return <span className='font-bold color-orange'>{field}</span>
  }
}

const handleProfit = (cell: Cell) => {
  if (cell.value === null) return '---'
  if ((cell.row.original as GetProfit).lending_type === LENDING_TYPE.Share) {
    return <span className='color-need-kuda'>{cell.value} %</span>
  } else
    return (
      <div className='display__flex'>
        <span className='color-kuda padding__r'>{cell.value}</span> 
        {(cell.row.original as GetProfit)?.rent_fee_currency === CURRENCY_TYPE?.KUDA
          ? <MemoizedImageKUDAOLD />
          : <MemoizedImageKUDA />}
      </div>
    )
}

const handlDisplayProfitOwner = (cell: Cell) => {
  const arrPrizeOwner = (cell?.row?.original as any)?.prize_for_owner || []
  const timeEndDate = (cell?.row?.original as any)?.end_date

  return (
    <>
      {(cell?.row?.original as any)?.lending_type === LENDING_TYPE.Available ? (
        (cell?.row?.original as any)?.rent_fee_currency === CURRENCY_TYPE?.KUDA
        ? <span className={`font-bold total-prize`}>
          <div className='display__flex'>
            <span className='color-kuda padding__r'>{(cell?.row?.original as any)?.rent_fee || NUMBER.ZERO}</span>
            <MemoizedImageKUDAOLD />
          </div>
        </span>
        : <span className={`font-bold total-prize`}>
          <div className='display__flex'>
            <span className='color-kuda padding__r'>{(cell?.row?.original as any)?.rent_fee || NUMBER.ZERO}</span>
            <MemoizedImageKUDA />
          </div>
        </span>
      ) : (
        <span className={`font-bold total-prize`}>
          { (
            checkExistsElementInArray(arrPrizeOwner, CURRENCY_TYPE?.MERAH_NEW) ||
            checkExistsElementInArray(arrPrizeOwner, CURRENCY_TYPE?.KUDA_NEW) ||
            (cell?.row?.original as any)?.rent_fee_currency === CURRENCY_TYPE?.MERAH_NEW ||
            (cell?.row?.original as any)?.rent_fee_currency === CURRENCY_TYPE?.KUDA_NEW
          ) ? (
              <>
                <div className='display__flex'>
                  <span className='color-kuda padding__r'>
                    {(findElementInArray(arrPrizeOwner, CURRENCY_TYPE?.MERAH_NEW) as any)?.amount || NUMBER.ZERO}
                  </span>
                  <MemoizedImageMERAH />
                </div>
                <div className='display__flex'>
                  <span className='color-kuda padding__r'>
                    {(findElementInArray(arrPrizeOwner, CURRENCY_TYPE?.KUDA_NEW) as any)?.amount || NUMBER.ZERO}
                  </span>
                  <MemoizedImageKUDA />
                </div>
              </>
            ) : (
              <>
              {timeEndDate - getTimeStampCurrent() >= 0 ||
                !(cell?.row?.original as any)?.prize_for_owner
               ? (
                <>
                  <div className='display__flex'>
                    <span className='color-kuda padding__r'>
                      {NUMBER.ZERO}
                    </span>
                    <MemoizedImageMERAH />
                  </div>
                  <div className='display__flex'>
                    <span className='color-kuda padding__r'>
                      {NUMBER.ZERO}
                    </span>
                    <MemoizedImageKUDA />
                  </div>
                </>
              ) : (
                <>
                  <div className='display__flex'>
                    <span className='color-kuda padding__r'>
                      {(findElementInArray(arrPrizeOwner, CURRENCY_TYPE?.MERAH) as any)?.amount || NUMBER.ZERO}
                    </span>
                    <MemoizedImageMERAHOLD />
                  </div>
                  <div className='display__flex'>
                    <span className='color-kuda padding__r'>
                      {(findElementInArray(arrPrizeOwner, CURRENCY_TYPE?.BIRU) as any)?.amount || NUMBER.ZERO}
                    </span>
                    <MemoizedImageBIRUOLD />
                  </div>
                </>
              )}
              </>
            )
          }
        </span>
      )}
    </>
  )
}

const handleDisplaySpecialHorseInfo = (cell: Cell) => {
  let horseHead: any = ''
  let horseBody: any = ''
  let horseLeg: any = ''
  let horseSkin: any = ''
  
  const horseInfo = (cell.row.original as any)
  horseInfo?.weapon_items_equipped?.map((item: any) => {
    switch (item?.body_part) {
      case STRING_ARMOR_SKIN.HEAD: {
        return horseHead = STRING_ARMOR_SKIN.HEAD
      }
      case STRING_ARMOR_SKIN.BODY: {
        return horseBody = STRING_ARMOR_SKIN.BODY
      }
      case STRING_ARMOR_SKIN.LEG: {
        return horseLeg = STRING_ARMOR_SKIN.LEG
      }
      case STRING_ARMOR_SKIN.SKIN: {
        return horseSkin = STRING_ARMOR_SKIN.SKIN
      }
      default:
        break;
    }
  })

  return (
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <div className='ct_sub_avata mg_left_8'>
        <img src={horseInfo?.sub_avatar} alt='sub avatar' width={40} />
      </div>
      <div className='horse__name__box'>
        <div className='horse__name__title'>
          <div className='cut-text'>{shortenRaceCourseNameClone(horseInfo?.horse_name)}</div>
        </div>
        <div>
          <div className='ct_armor_skin'>
            <ImageArmorSkin
              horseHead={horseHead}
              horseBody={horseBody}
              horseLeg={horseLeg}
              horseSkin={horseSkin}
              weaponItemsEquipped={horseInfo?.weapon_items_equipped || []}
              width={20}
              height={20}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
const guildHorseInfor = (cell: Cell, user?: CurrentUser) => {
  const isOwner = user && (cell.row.original as any).owner?.id === user.id ? true : false

  let horseHead: any = ''
  let horseBody: any = ''
  let horseLeg: any = ''
  let horseSkin: any = ''
  const horseInfo = (cell.row.original as any)?.horse
  horseInfo?.weapon_items_equipped?.map((item: any) => {
    switch (item?.body_part) {
      case STRING_ARMOR_SKIN.HEAD: {
        return horseHead = STRING_ARMOR_SKIN.HEAD
      }
      case STRING_ARMOR_SKIN.BODY: {
        return horseBody = STRING_ARMOR_SKIN.BODY
      }
      case STRING_ARMOR_SKIN.LEG: {
        return horseLeg = STRING_ARMOR_SKIN.LEG
      }
      case STRING_ARMOR_SKIN.SKIN: {
        return horseSkin = STRING_ARMOR_SKIN.SKIN
      }
      default:
        break;
    }
  })

  return (
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <div className='ct_sub_avata'>
        <img src={(cell.row.original as GetSpecialRace).sub_avatar} alt='sub avatar' width={40} />
      </div>
      <div className='horse__name__box'>
        <div className='horse__name__title'>
          <div className={`cut-text ${isOwner ? 'color-hightlight' : ''}`}>
            {shortenRaceCourseNameClone((cell.row.original as GetSpecialRace).horse_name)}
          </div>
        </div>
        <div>
          <div className='ct_armor_skin'>
            <ImageArmorSkin
              horseHead={horseHead}
              horseBody={horseBody}
              horseLeg={horseLeg}
              horseSkin={horseSkin}
              weaponItemsEquipped={horseInfo?.weapon_items_equipped || []}
              width={20}
              height={20}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const guildHorseBlackList = (cell: Cell, user?: CurrentUser) => {
  const isOwner = user && (cell.row.original as any).owner?.id === user.id ? true : false

  let horseHead: any = ''
  let horseBody: any = ''
  let horseLeg: any = ''
  let horseSkin: any = ''
  const horseInfo = (cell.row.original as any)
  horseInfo?.weapon_items_equipped?.map((item: any) => {
    switch (item?.body_part) {
      case STRING_ARMOR_SKIN.HEAD: {
        return horseHead = STRING_ARMOR_SKIN.HEAD
      }
      case STRING_ARMOR_SKIN.BODY: {
        return horseBody = STRING_ARMOR_SKIN.BODY
      }
      case STRING_ARMOR_SKIN.LEG: {
        return horseLeg = STRING_ARMOR_SKIN.LEG
      }
      case STRING_ARMOR_SKIN.SKIN: {
        return horseSkin = STRING_ARMOR_SKIN.SKIN
      }
      default:
        break;
    }
  })  

  return (
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <div className='ct_sub_avata'>
        <img src={horseInfo?.sub_avatar} alt='sub avatar' width={40} />
      </div>
      <div className='horse__name__box'>
        <div className='horse__name__title'>
          <div className={`cut-text ${isOwner ? 'color-hightlight' : ''}`}>
            {shortenRaceCourseNameClone(horseInfo?.horse_name)}
          </div>
        </div>
        <div>
          <div className='ct_armor_skin'>
            <ImageArmorSkin
              horseHead={horseHead}
              horseBody={horseBody}
              horseLeg={horseLeg}
              horseSkin={horseSkin}
              weaponItemsEquipped={horseInfo?.weapon_items_equipped || []}
              width={20}
              height={20}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const lendingHorseName = (cell: Cell, isShowVipIcon?: boolean) => {
  return (
    <div className='row-name'>
      <span>{cell.render('Cell')}</span>
      {isShowVipIcon && ((cell.row.original as GuildHorseInfo)?.vip_pass) && (
        <VipIcon />
      )}
    </div>
  )
}

const guildMemberInfor = (cell: Cell, user?: CurrentUser, isShowVipIcon?: boolean) => {
  const isOwner = user && (cell.row.original as any).id === user.id ? true : false
  return (
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <div className={`cut-text ${isOwner ? 'color-hightlight' : ''}`}>
        {shortenRaceNameV2((cell.row.original as any).user_name)}
      </div>
    </div>
  )
}

const guildListInfor = (cell: Cell, user?: CurrentUser) => {
  const isOwner = user && (cell.row.original as any)?.id === user?.guild_id ? true : false
  return (
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <div className={`cut-text ${isOwner ? 'color-hightlight' : ''}`}>
        <span dangerouslySetInnerHTML={{ __html: cell?.value }} />
      </div>
    </div>
  )
}

const guildRenterInfor = (cell: Cell, user?: CurrentUser) => {
  const isRenter = user && (cell.row.original as any)?.renter?.id === user.id ? true : false
  return (
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <div className={`cut-text ${isRenter ? 'color-hightlight' : ''}`}>{shortenRaceCourseNameClone(cell?.value)}</div>
    </div>
  )
}

const guildInfor = (cell: Cell) => {
  return (
    <div className='d-flex align-items-center justify-content-center gap-2'>
      <div className='contain_img'>
        {(cell.row.original as GuildInfo)?.horse_guild?.avatar ? (
          <img src={(cell.row.original as GuildInfo)?.horse_guild?.avatar} alt='avatar' width={40} />
        ) : (
          <div>---</div>
        )}
      </div>
      {(cell.row.original as GuildInfo)?.horse_guild?.guild_tag ? (
        <span className='color-yellow'>[{(cell.row.original as GuildInfo)?.horse_guild?.guild_tag}]</span>
      ) : (
        <div>---</div>
      )}
    </div>
  )
}

const getImageRow = (status: any = "", weatherCurrent: any = "", cellImage: any = "", category: any = "") => {
  return getImgWeather((status === STRING_STATUS.OPEN || (status === STRING_STATUS.SCHEDULING && category === STRING_CATEGORY.SPECIAL)) ? weatherCurrent : cellImage)
}

const getImageArmorSkin = (horseInfo: any) => {
  const weaponItemsEquipped = horseInfo?.weapon_items_equipped || []
  let horseHead: any = ''
  let horseBody: any = ''
  let horseLeg: any = ''
  let horseSkin: any = ''
  horseInfo?.weapon_items_equipped?.map((item: any) => {
    switch (item?.body_part) {
      case STRING_ARMOR_SKIN.HEAD: {
        return horseHead = STRING_ARMOR_SKIN.HEAD
      }
      case STRING_ARMOR_SKIN.BODY: {
        return horseBody = STRING_ARMOR_SKIN.BODY
      }
      case STRING_ARMOR_SKIN.LEG: {
        return horseLeg = STRING_ARMOR_SKIN.LEG
      }
      case STRING_ARMOR_SKIN.SKIN: {
        return horseSkin = STRING_ARMOR_SKIN.SKIN
      }
      default:
        break;
    }
  })

  return (
    <div className='ct_armor_skin'>
      <ImageArmorSkin
        horseHead={horseHead}
        horseBody={horseBody}
        horseLeg={horseLeg}
        horseSkin={horseSkin}
        weaponItemsEquipped={weaponItemsEquipped}
      />
    </div>
  )
}

const guildHorseNameArmorSkin = (cell: Cell, user?: CurrentUser) => {
  const isOwner = user && (cell.row.original as any)?.horseNameArmorSkin?.owner?.id === user.id ? true : false
  const weaponItemsEquipped = (cell.row.original as any)?.horseNameArmorSkin?.weapon_items_equipped || []
  let horseHead: any = ''
  let horseBody: any = ''
  let horseLeg: any = ''
  let horseSkin: any = ''
  const horseInfo = (cell.row.original as any)
  horseInfo?.horseNameArmorSkin?.weapon_items_equipped?.map((item: any) => {
    switch (item?.body_part) {
      case STRING_ARMOR_SKIN.HEAD: {
        return horseHead = STRING_ARMOR_SKIN.HEAD
      }
      case STRING_ARMOR_SKIN.BODY: {
        return horseBody = STRING_ARMOR_SKIN.BODY
      }
      case STRING_ARMOR_SKIN.LEG: {
        return horseLeg = STRING_ARMOR_SKIN.LEG
      }
      case STRING_ARMOR_SKIN.SKIN: {
        return horseSkin = STRING_ARMOR_SKIN.SKIN
      }
      default:
        break;
    }
  })

  return (
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <div className='ct_sub_avata'>
        <img src={horseInfo?.horseNameArmorSkin?.sub_avatar} alt='sub avatar' width={40} />
      </div>
      <div className='horse__name__box'>
        <div className='horse__name__title'>
          <div className={`cut-text ${isOwner ? 'color-hightlight' : ''}`}>
            <span className='color-yellow pd__rigth_3'>
              {horseInfo?.horseNameArmorSkin?.guild ? getGuildTag(horseInfo?.horseNameArmorSkin?.guild?.guild_tag) : ''}
            </span>
            {shortenRaceCourseNameClone(horseInfo?.horseNameArmorSkin?.name)}
          </div>
        </div>
        <div>
          <div className='ct_armor_skin'>
            <ImageArmorSkin
              horseHead={horseHead}
              horseBody={horseBody}
              horseLeg={horseLeg}
              horseSkin={horseSkin}
              weaponItemsEquipped={weaponItemsEquipped}
              width={20}
              height={20}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const renderDataTable = (
  cell: Cell,
  header: string,
  time: number,
  toggleIsModalConfirmCancelOpen?: (value?: boolean) => void,
  status?: string,
  renderConfirmCancelHorseModal?: (value?: any) => void,
  onClickOneCell?: (value: any) => void,
  currentUser?: CurrentUser,
  getWeatherSelector?: WeatherModel,
  isShowVipIcon?: boolean,
  handleOpenModalBloodline?: any
) => {
  const _handleClickImageBloodline = (event: React.MouseEvent, type: string, content: string) => {
    event.stopPropagation()
    handleOpenModalBloodline(type, content)
  }

  switch (header) {
    case accessor.cancelCountDown:
      return (
        <div className='d-flex align-items-center justify-content-start gap-2 count-down-btns'>
          <>
            {time > NUMBER.ZERO ? (
              <button disabled className='btn-waiting-cancel'>
                {<img src={CANCEL_COUNT_DOWN_DISABLE} />}
                <span className='color-yellow'>{timeConversion(time)}</span>
              </button>
            ) : status === STATUS.SCHEDULING || status === STATUS.WAITING ? (
              <> </>
            ) : (
              <button
                onClick={() => {
                  toggleIsModalConfirmCancelOpen?.(true)
                  renderConfirmCancelHorseModal?.(cell.value)
                }}
              >
                <img src={CANCEL_COUNT_DOWN} />
              </button>
            )}
          </>
          {renderConfirmCancelHorseModal?.(cell.value)}
        </div>
      )
    case accessor.racePosition:
      return <div dangerouslySetInnerHTML={{ __html: ordinalSuffixOf(cell.value) }} />
    case accessor.count_down:
      return handleStartIn(cell)
    case accessor.grade:
      return <ClassTag text={cell.value} isActive={true} />
    case accessor.totalPrize:
      return (cell?.value ? handleDisplayTotalPrize(cell, "price") : <span>---</span>)
    case accessor.totalPrizeT:
      return handleDisplayTotalPrizeT(cell)
      // return (cell?.value ? handleDisplayTotalPrizeT(cell) : <span>---</span>)
    case accessor.jackpot:
      return (cell?.value ?
        <span className='jackpot_content'>
          <MemoizedLoadImageCommon srcImg={JACKPOT_ANIM} />
        </span> :
        <span className='jackpot_content'>---</span>)
    case accessor.guildJackpot:
      return ((cell.row.original as any)?.jackpot?.jackpot ?
        <span className='jackpot_content'>
          <MemoizedLoadImageCommon srcImg={JACKPOT_ANIM} />
        </span> :
        <span className='jackpot_content'>---</span>)
    case accessor.recervedPrize:
      return (cell?.value ? handleDisplayTotalPrize(cell, "received_price") : <span>---</span>)
    case accessor.raceType:
      return <RaceType raceType={cell.value} posTooltip={false} />
    case accessor.racePrize:
      return handleDisplayRacePrize(cell)
    case accessor.experienceReceived:
      return <span className='font-bold'>{cell.value}</span>
    case accessor.field:
      return <span>{handleFieldType(cell.value) ?? ''}</span>
    case accessor.distance:
      return <span className='font-bold'>{cell.value ? `${(cell.value as number).toLocaleString()}m` : '0m'}</span>
    case accessor.entryFee:
      return (
        <div className='entry-fee text-uppercase font-bold display__flex'>
          {cell.value >= NUMBER.ZERO && (cell.value === NUMBER.ZERO ? (
            <span className='color-primary font-bold'>{STRING.FREE}</span>
          ) : (
            <>
              <span className='color-kuda font-bold'>{cell.value}</span>{' '}
              {(cell.row.original as any).legacy_data
              ? <MemoizedImageKUDAOLD />
              : <MemoizedImageKUDA />}
            </>
          ))}
        </div>
      )
    case accessor.horse:
      return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
    case accessor.gender:
      return <div className='horse-gender-open mg__left'>
        <MemoizedLoadImageCommon srcImg={getImageGender(cell.value)} />
      </div>
    case accessor.region:
      return <div className='img__icon mg__right_5'>
        {
          <div className='region'>
            <MemoizedLoadImageCommon srcImg={getImageRegion(cell.value)} />
          </div>
        }
      </div>
    case accessor.isNewRecord:
      return <div className='img_new_record'>
          {(cell.row.original as any)?.record_detail?.is_new_record ? (
            <MemoizedLoadImageCommon srcImg={NEW_RECORD} />
          ) : ""}
        </div>
    case accessor.horseNameArmorSkin:
      return <div>{guildHorseNameArmorSkin(cell, currentUser)}</div>
    case accessor.highlight:
      return <img className='icon-highlight' src={cell.value ? ICON_HIGHLIGHT : '#'} alt="" />
    case accessor.registered:
      return (
        <div className='font-bold register'>
          <div>{cell.value}/{(cell.row.original as any)?.max_gate ?? 12}</div>
        </div>
      )
    case accessor.startIn:
    case accessor.endAt:
      return (
        <span className='value color-white font-bold'>{convertFullDate(parseInt(cell.value))}</span>
      )
    case accessor.gate:
      return <div dangerouslySetInnerHTML={{ __html: cell.value }} className='padding_left_8'/>
    case accessor.statistic:
      return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
    case accessor.ownerName:
      return (
        <div
          className='cut-text'
          dangerouslySetInnerHTML={{
            __html:
              `<span class='color-yellow'>  ${getGuildTag((cell.row.original as any).owner_guild_tag)} </span>` +
              ' ' +
              shortenUserName(cell.value)
          }}
        />
      )
    case accessor.weaponItemsEquipped:
      return getImageArmorSkin(cell.row.original as any)
    case accessor.bloodLine:
      return <div
        onClick={(event) => _handleClickImageBloodline(event, STRING_BLOODLINE_CHARACTER.BLOODLINE, (cell.row.original as any)?.blood_line_name)}
        className='image_bloodline'>
        <MemoizedLoadImageCommon srcImg={getImageBloodline(cell.value)} />
      </div>
    case accessor.avatar:
      return <div className='avatar-box'>{<img src={cell.value ?? AVATAR_DEFAULT} alt='' className='avatar' />}</div>
    case accessor.raceName:
      return <span className='font-bold race-name'>
          <span className='race__title'>{handleRaceName(cell)}</span>
          {(cell.row.original as any)?.protected_race &&
            <span className='contain__icon'><img src={ICON_LOCK} loading="lazy" alt=' lock' /></span>}
        </span>
    case accessor.raceCourse:
      return <span className={`font-bold ${getBackgroundRegion(cell.value)}`}>{cell?.value?.toUpperCase()}</span>
    case profitColumns.no:
      return <span className='font-bold'>{cell.row.index + NUMBER.ONE}</span>
    case profitColumns.rent_fee:
      return <span className='font-bold'>{handleProfit(cell)}</span>
    case profitColumns.prize_for_owner:
      return <span className='font-bold'>{handlDisplayProfitOwner(cell)}</span>
    case profitColumns.horse_name:
      return <div>{lendingHorseName(cell, isShowVipIcon)}</div>
    case accessor.raceNo:
      return (
        <span className='font-bold'>
          <div className='color-orange-200'>{cell.value}</div>
          <div className='font-size-16'>{convertShortTime((cell.row.original as RecordRace).real_start_at)}</div>
        </span>
      )
    case specialRace.weather:
    case accessor.weather:
      return (
        <span className='icon-weather'>
          <MemoizedLoadImageCommon
            srcImg={getImageRow((cell?.row?.original as any)?.status, getWeatherSelector?.weather?.name, cell?.value?.name, (cell?.row?.original as any)?.category)}
          />
        </span>
      )
    case specialRace.horse_gender:
      return <div className='horse-gender-open mg__left mg__right_5'>
        <MemoizedLoadImageCommon srcImg={getImageGender(cell.value)} />
      </div>
    case specialRace.region:
      return <div className='img__icon'>
        {
          <div className='region'>
            <MemoizedLoadImageCommon srcImg={getImageRegion(cell.value)} />
          </div>
        }
      </div>
    case specialRace.horseInfo:
      return <span className='font-bold '>{handleDisplaySpecialHorseInfo(cell)} </span>
    case specialRace.inDate:
      return <div className='value font-bold'>{convertFullDate(cell.value)} </div>
    case specialRace.chooseToRace:
      if (cell.value) {
        return (
          <div className='value font-bold'>
            {convertFullDate(cell.value)}{' '}
            <div className='color-orange-200'>{(cell.row.original as RecordRace).race_no}</div>
          </div>
        )
      } else return <div>No selected</div>
    case specialRace.career:
      return (
        <div>
          <span className='font-bold career-horse color-yellow'>{cell.value.total_number_of_races} &nbsp;</span>
          <span className='font-bold'> {cell.value.first_count}/</span>
          <span className='font-bold'>{cell.value.second_count}/</span>
          <span className='font-bold'>{cell.value.third_count}</span>
        </div>
      )

    case specialRace.eventOpenTime:
      return (
        <div className='value font-bold'>
          {convertShortDay(cell.value * NUMBER.THOUSAND)} {convertShortTime(cell.value * NUMBER.THOUSAND)}
        </div>
      )
    case specialRace.eventEndTime:
      return <div className='value font-bold'>{convertFullDate(cell.value * NUMBER.THOUSAND)}</div>
    case specialRace.totalHorseInRace:
      return <div className='font-bold'>{convertPluralText(cell.value, 'horse')} </div>
    case specialRace.totalRace:
      return <div className='font-bold'>{convertPluralText(cell.value, 'race')}</div>
    case specialRace.totalHorse:
      return <div className='font-bold'>{convertPluralText(cell.value, 'horse')}</div>
    case specialRace.EventId:
      return <div className='font-bold'>{cell.row.index + 1}</div>
    case guild.chooseToGuild:
      return (
        <div className='font-bold '>
          <ButtonCustom
            onClickButton={event => {
              event.stopPropagation()
              onClickOneCell && onClickOneCell((cell.row.original as RecordRace).id)
            }}
            disabled={cell.row.values.disabled}
            buttonName='Join'
          />
        </div>
      )
    case guild.manage:
      return currentUser && currentUser?.id === (cell.row.original as RecordRace).id ? (
        <></>
      ) : (
        <div className='font-bold '>
          <ButtonCustom
            onClickButton={event => {
              event.stopPropagation()
              onClickOneCell && onClickOneCell(cell.row.original)
            }}
            buttonName='Manage'
            disabled={
              currentUser?.role_in_guild === ROLE_GUILD.member ||
              (cell.row.original as GuildMembersResponse).role === ROLE_GUILD.master ||
              ((cell.row.original as GuildMembersResponse).role === ROLE_GUILD.manager &&
                currentUser?.role_in_guild === ROLE_GUILD.manager) ||
              !currentUser?.guild_id
            }
          />
        </div>
      )
    case guild.status:
      return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
    case guild.guildHorse:
      return <div>{guildHorseInfor(cell, currentUser)}</div>
    case guild.guildVipPass:
      return <div className='img__icon'>
        {isShowVipIcon && ((cell.row.original as GuildHorseInfo).horse?.msp_details?.msp) && (
          <VipIcon />
        )}
      </div>
    case guild.guildGender:
      return <div className='horse-gender-open mg__left mg__right_5'>
        <MemoizedLoadImageCommon srcImg={getImageGender(cell.value)} />
      </div>
    case guild.guildRegion:
      return <div className='img__icon'>
        {
          <div className='region'>
            <MemoizedLoadImageCommon srcImg={getImageRegion(cell.value)} />
          </div>
        }
      </div>
    case guild.guildVipPassBlackList:
      return <div className='img__icon'>
        { cell?.value &&
          <div className='region'>
            <MemoizedLoadImageCommon srcImg={getImageRegion(cell.value)} />
          </div>
        }
      </div>
    case guild.guildDopping:
    case specialRace.specialDopping:
      return <div className='img__icon'>
        {currentUser && (cell.row.original as any).horse?.equipped_boost_item && (
          <MemoizedLoadImageCommon srcImg={VIEW_DOPING} />
        )}
      </div>
    case specialRace.specialNewRecord:
      return <div className='img_new_record'>
          {(cell.row.original as any)?.is_new_record ? (
            <MemoizedLoadImageCommon srcImg={NEW_RECORD} />
          ) : ""}
        </div>
    case specialRace.specialRegion:
      return <div className='img__icon'>
        {currentUser && (cell.row.original as any)?.region && (
          <div className='region'>
            <MemoizedLoadImageCommon srcImg={getImageRegion((cell.row.original as any)?.region)} />
          </div>
        )}
      </div>
    case guild.guildNewRecord:
      return <div className='img_new_record'>
          {(cell.row.original as any)?.horse?.is_new_record ? (
            <MemoizedLoadImageCommon srcImg={NEW_RECORD} />
          ) : ""}
        </div>
    case guild.guildDoppingBlackList:
      return <div className='img__icon'>
        {currentUser && (cell.row.original as any)?.equipped_boost_item && (
          <MemoizedLoadImageCommon srcImg={VIEW_DOPING} />
        )}
      </div>
    case guild.guildHorseBlackList:
      return <div>{guildHorseBlackList(cell, currentUser)}</div>
    case guild.member:
      return <div>{guildMemberInfor(cell, currentUser, isShowVipIcon)}</div>
    case guild.renterName:
      return <div>{guildRenterInfor(cell, currentUser)}</div>
    case guild.guildName:
      return <div>{guildListInfor(cell, currentUser)}</div>
    case guild.guildTag:
      return <div>{guildInfor(cell)}</div>
    case profitColumns.renter_name:
      return (
        <div>
          <span className='color-yellow'> {getGuildTag((cell.row.original as any).renter_guild_tag)} </span>{' '}
          {shortenRaceCourseNameClone(cell?.value)}
        </div>
      )
    case guild.approve:
      return (
        <div>
          <ButtonTable
            buttonName={'Approve'}
            onClickButton={event => {
              event.stopPropagation()
              onClickOneCell && onClickOneCell(cell.row.original)
            }}
            height={30}
            width={120}
          />
        </div>
      )
    case guild.cancel:
      return (
        <div>
          <ButtonTable
            buttonName={'Cancel'}
            onClickButton={event => {
              event.stopPropagation()
              onClickOneCell && onClickOneCell(cell.row.original)
            }}
            type="btnCancel"
            height={30}
            width={120}
          />
        </div>
      )
    
    default:
      return (
        <div className='row-name'>
          <span>{cell.render('Cell')}</span>
          {/* {isShowVipIcon && ((cell.row.original as GuildHorseInfo)?.vip_pass) && (
            <VipIcon />
          )} */}

        </div>
      )
  }
}

const isEmptyGate = (row: any): row is Gate => row.horse === null && typeof row.gate === 'number'

function RaceTable({
  columns,
  data,
  isRowClickable = false,
  onRegisterButtonClick,
  raisePage,
  loader = false,
  params,
  status,
  onQuickView,
  currentUser,
  paramsFilterResult,
  checkLoadingPage,
  timeCancelRace,
  toggleIsModalConfirmCancelOpen,
  totalRace,
  isModalConfirmCancelOpen,
  onRowClick,
  noDataText,
  isHorseTable,
  onClickOneCell,
  isShowVipIcon = false,
  disableSelectGate = false
}: RaceTableProps) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { pathname: currentPathname } = useLocation()
  const getWeatherSelector = useSelector(weatherSelector)
  const [bloodlineTitle, setBloodlineTitle] = useState('')
  const [bloodlineContent, setBloodlineContent] = useState('')
  const [openHorseBloodlineModal, toggleOpenHorseBloodlineModal] = useToggle(false)

  const fetchCoinUser = async () => {
    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return

    dispatch(setCoinUser(resultCoinUser.data))
  }

  const renderConfirmCancelHorseModal = (value: any) => {
    if (isModalConfirmCancelOpen) {
      const handleOk = () => {
        handleCancelCountDown(value).then(() => fetchCoinUser())
        toggleIsModalConfirmCancelOpen?.(false)
      }

      const handleCancel = () => {
        toggleIsModalConfirmCancelOpen?.(false)
      }
      return (
        <ConfirmCancelModal toggleIsModalOpen={handleCancel} onCloseButtonClick={handleCancel} onConfirm={handleOk} />
      )
    }
  }  
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })
  const memoizedRenderDataTable = useCallback(renderDataTable, [
    columns,
    data,
    timeCancelRace,
    status,
    renderConfirmCancelHorseModal
  ])
  const tableBodyClass = classNames('table-body', { ['highlight']: status === STRING_STATUS.RESULT || status === STRING_STATUS.CLOSED })

  const handleRowItemClicked = (value: any) => {
    if (onRowClick) {
      if (isHorseTable) {
        return () => onRowClick(value)
      } else return () => onRowClick(value.id)
    }
    if (!isRowClickable) return

    const slicePathName = currentPathname?.split('/').slice(1)[0]
    if (slicePathName === paths.subDomainSpecialRace.index()) {
      return () => navigate(links.specialRace.raceDetail(value.id.toString()))
    } else {
      return () => navigate(links.race.detail(value.id.toString()))
    }
  }

  const handleRegisterButtonClick = (gateNumber: number) => () => {
    if (!onRegisterButtonClick) return

    onRegisterButtonClick(gateNumber)
  }

  const handleQuickView = (raceId: any) => () => {
    if (!onQuickView) return

    onQuickView(raceId)
  }

  const handleLoadingRaces = () => {
    if (loader === false) {
      return <ReactLoading className='loading' type={'spin'} />
    } else if (totalRace === 0) {
      return <div className='no-data'>{noDataText ? noDataText : t(`${NOTIFICATION_MESSAGE}.dataNotFound`)}</div>
    } else return <></>
  }

  const checkTextAlign = (cell: Cell) => {
    if (
      cell.column.Header === 'entry fee' ||
      cell.column.Header === 'total_prize' ||
      cell.column.Header === 'prize' ||
      cell.column.id === 'received_prize'
    ) {
      return `max-width-80 justify-content-end`
    }

    return `justify-content-start`
  }

  const checkHeader = (column: any) => {
    if (column.Header === 'entry fee') {
      return `padding-left-header-10`
    }
    if (column.Header === 'prize') {
      return `text-center`
    }
  }

  const handleOpenModalBloodline = (type: string, content: string) => {
    setBloodlineTitle(type)
    setBloodlineContent(content)
    toggleOpenHorseBloodlineModal(true)
  }

  const _handleClosePopup = () => {
    toggleOpenHorseBloodlineModal(false)
  }

  return (
    <RaceTableStyled isRowClickable={isRowClickable}>
      <div className='race-table-container'>
        <div className='race-table'>
          <InfiniteScroll
            dataLength={data.length}
            next={() =>
              typeof raisePage === 'function' &&
              data.length >= 20 &&
              raisePage?.(
                checkLoadingPage
                  ? { ...paramsFilterResult, page: paramsFilterResult && paramsFilterResult.page + 1 }
                  : { ...params, page: params.page + 1 }
              )
            }
            hasMore={true}
            loader={handleLoadingRaces()}
          >
            <table {...getTableProps()} className='table'>
              <thead className='table-head'>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index} className='table-row'>
                    {headerGroup.headers.map((column, index) => {
                      return (
                        <th
                          {...column.getHeaderProps()}
                          key={column.id}
                          className={`th text-uppercase font-bold text-left ${index === 0 && 'ps-3'} ${checkHeader(
                            column
                          )}
                          `}
                        >
                          <div dangerouslySetInnerHTML={{ __html: column.render('Header') as any }} />
                          {/* {column.render('Header')} */}
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className={tableBodyClass}>
                {rows.map((row: any) => {
                  const userLoginId = currentUser?.id
                  prepareRow(row)
                  if (isEmptyGate(row.original)) {
                    return (
                      <tr {...row.getRowProps()} key={row.id} className='table-row'>
                        {status === STRING_STATUS.CLOSED ? <td className='table-data text-left font-bold'></td> : ''}
                        <td className='table-data text-left font-bold'>
                          <div className={`table-gate-no no-${row.original.gate}`}>
                            <span className='gate-no'>{row.original.gate}</span>
                          </div>
                        </td>
                        <td className={`font-bold table-data-empty ${disableSelectGate ? 'btn_disabled' : ''}`} colSpan={8}>
                          <button
                            className={`btn-select-gate ${disableSelectGate ? 'btn_disabled' : ''}`}
                            onClick={handleRegisterButtonClick(row.original.gate as unknown as number)}
                            disabled={disableSelectGate}
                          >
                            {t(`${NOTIFICATION_MESSAGE}.selectThisGate`)}
                          </button>
                        </td>
                      </tr>
                    )
                  }
                  const handleDetailHorse = (row: any): row is Gate[] => {
                    return row.original.detailHorse
                  }

                  const normal = 'table-row position-relative'
                  const hightLight = 'table-row position-relative table-row-primary'
                  return (
                    <tr
                      {...row.getRowProps()}
                      key={row.id}
                      // Check info user_id and owner-user_id
                      className={userLoginId && userLoginId === row.original.user_id ? hightLight : normal}
                    >
                      {row.cells.map((cell: any, index: number) => {                        
                        return cell.column.id === 'cancel_count_down' &&
                          !(userLoginId && userLoginId === row.original.user_id) ? (
                          <Fragment key={cell.column.id}></Fragment>
                        ) : (
                          <td
                            onClick={
                              cell.column.id !== 'cancel_count_down' ?
                              (handleRowItemClicked(row.original as RecordRace) ||
                                handleQuickView(handleDetailHorse(row) as unknown as Race))
                              : undefined
                            }
                            {...cell.getCellProps()}
                            key={cell.column.id}
                            className={
                              `table-data text-center p-0 position-relative ${status?.toLowerCase()} ${cell?.column?.id} ${index === 0 ? 'ps-2' : ''
                              }`}
                          >
                            <div
                              className={`table-data-container d-flex h-100 align-items-center ${checkTextAlign(cell)}`}
                            >
                              {memoizedRenderDataTable(
                                cell,
                                cell.column.id,
                                timeCancelRace || 0,
                                toggleIsModalConfirmCancelOpen,
                                status,
                                renderConfirmCancelHorseModal,
                                onClickOneCell,
                                currentUser,
                                getWeatherSelector,
                                isShowVipIcon,
                                handleOpenModalBloodline
                              )}
                            </div>
                          </td>
                          )
                        })}
                    </tr>
                    )
                })}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>

        {openHorseBloodlineModal && (
          <ModalHorseBloodline
            title={bloodlineTitle}
            content={bloodlineContent}
            onClose={_handleClosePopup}
          />
        )}
      </div>
    </RaceTableStyled>
  )
}

export default RaceTable
