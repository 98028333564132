/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks'
import MemoizedImageHorsePopupClose from 'shared/MemoizedImage/HorsePopupClose'
import { Modal } from 'shared'
import {
  // ARMOR_ICON,
  GAME_TOKEN_KUDA,
  GAME_TOKEN_MERAH,
  ICON_ADIL,
  JACKPOT_ANIM,
  LINE_TEXT,
  NEW_RECORD
  // SKIN_ICON,
  // VIEW_DOPING
} from 'assets/images'
import { Spin } from 'antd'
import MemoizedImageRaratyCommon from 'shared/MemoizedImage/RaratyCommon'
import MemoizedImageRaratyEpic from 'shared/MemoizedImage/RaratyEpic'
import MemoizedImageRaratyLegendary from 'shared/MemoizedImage/RaratyLegendary'
import MemoizedImageRaratyMythic from 'shared/MemoizedImage/RaratyMythic'
import MemoizedImageRaratyRare from 'shared/MemoizedImage/RaratyRare'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import { newRecordSelector, rewardJackpotSelector } from 'apps/redux/jackpot/selector'
import { getRewardJackpot, getRewardRecord } from 'apps/redux/jackpot/action'
import { numberWithCommas } from 'utils/helper'
import { JackpotDetailStyle } from './styled'
import { ShopTypeRariTy } from 'models'

interface MProps {
  onClose: () => void
  jackpot?: any
  raceId?: any
  horseId?: any
  width?: any
  height?: any
}

const ModalJackpotDetail = ({ onClose, jackpot, raceId = 0, horseId = 0, width = 550, height = 150 }: MProps) => {
  const dispatch = useAppDispatch()
  const getRewardJackpotSelector = useSelector(rewardJackpotSelector)
  const getNewRecordSelector = useSelector(newRecordSelector)
  const [totalArmor, setTotalArmor] = useState(0)
  const [totalSkin, setTotalSkin] = useState(0)
  const [totalItem, setTotalItem] = useState(0)
  const [totalKuda, setTotalKuda] = useState(0)
  const [totalMerah, setTotalMerah] = useState(0)
  const [totalAdil, setTotalAdil] = useState(0)
  const [loadingModal, setLoadingModal] = useState(true)
  // const [checkDisplay, setCheckDisplay] = useState(true)
  const [checkReward, setCheckReward] = useState(true)
  const [totalArmorRecord, setTotalArmorRecord] = useState(0)
  const [totalSkinRecord, setTotalSkinRecord] = useState(0)
  const [totalItemRecord, setTotalItemRecord] = useState(0)
  const [totalKudaRecord, setTotalKudaRecord] = useState(0)
  const [totalMerahRecord, setTotalMerahRecord] = useState(0)
  const [totalAdilRecord, setTotalAdilRecord] = useState(0)
  // const [checkDisplayRecord, setCheckDisplayRecord] = useState(true)
  const [checkRewardRecord, setCheckRewardRecord] = useState(true)

  useEffect(() => {
    dispatch(getRewardJackpot({ raceId, horseId }))
    dispatch(getRewardRecord({ raceId, horseId }))
    if (jackpot) {
      jackpot?.jackpotReward?.rewards_details?.map((jack: any) => {
        switch (jack?.item_type) {
          case "HEAD":
          case "BODY":
          case "LEG": {
            return setTotalArmor(prev => +prev + +jack?.amount)
          }
          case "SKIN": {
            return setTotalSkin(prev => +prev + +jack?.amount)
          }
          case "BOOSTER": {
            return setTotalItem(prev => +prev + +jack?.amount)
          }
          case "COIN": {
            if (jack?.parent_sub_type === "KUDA_NEW") {
              return setTotalKuda(prev => +prev + +jack?.amount)
            } else if (jack?.parent_sub_type === "MERAH_NEW") {
              return setTotalMerah(prev => +prev + +jack?.amount)
            } else {
              return setTotalAdil(prev => +prev + +jack?.amount)
            }
          }
          default:
            break;
        }
      })

      jackpot?.newRecord?.rewards_details?.map((record: any) => {
        switch (record?.item_type) {
          case "HEAD":
          case "BODY":
          case "LEG": {
            return setTotalArmorRecord(prev => +prev + +record?.amount)
          }
          case "SKIN": {
            return setTotalSkinRecord(prev => +prev + +record?.amount)
          }
          case "BOOSTER": {
            return setTotalItemRecord(prev => +prev + +record?.amount)
          }
          case "COIN": {
            if (record?.parent_sub_type === "KUDA_NEW") {
              return setTotalKudaRecord(prev => +prev + +record?.amount)
            } else if (record?.parent_sub_type === "MERAH_NEW") {
              return setTotalMerahRecord(prev => +prev + +record?.amount)
            } else {
              return setTotalAdilRecord(prev => +prev + +record?.amount)
            }
          }
          default:
            break;
        }
      })

      const timeOut = setTimeout(() => {
        setLoadingModal(false)
        clearTimeout(timeOut)
      }, 1000);
    }
  }, [])

  useEffect(() => {
    if (jackpot?.jackpotReward?.rewards_details?.length && !getRewardJackpotSelector) {
      setCheckReward(false)
    } else {
      setCheckReward(true)
    }
  }, [getRewardJackpotSelector])

  useEffect(() => {
    if (jackpot?.newRecord?.rewards_details?.length && !getNewRecordSelector) {
      setCheckRewardRecord(false)
    } else {
      setCheckRewardRecord(true)
    }
  }, [getNewRecordSelector])


  const showIconRariTyCommon = (valueRariry: string) => {
    const handleKeySort = () => {
      switch (valueRariry) {
        case ShopTypeRariTy.ALL:
          return ''
        case ShopTypeRariTy.COMMON.toLocaleUpperCase(): {
          return <MemoizedImageRaratyCommon />
        }
        case ShopTypeRariTy.EPIC.toLocaleUpperCase(): {
          return <MemoizedImageRaratyEpic />
        }
        case ShopTypeRariTy.LEGENDARY.toLocaleUpperCase(): {
          return <MemoizedImageRaratyLegendary />
        }
        case ShopTypeRariTy.MYTHIC.toLocaleUpperCase(): {
          return <MemoizedImageRaratyMythic />
        }
        case ShopTypeRariTy.RARE.toLocaleUpperCase(): {
          return <MemoizedImageRaratyRare />
        }
        default:
          return valueRariry
      }
    }
    return handleKeySort()
  }

  // const handleClickJackpot = () => {
  //   if (checkDisplay) {
  //     dispatch(getRewardJackpot({ raceId, horseId }))
  //   }
  //   setCheckDisplay(!checkDisplay)
  // }

  // const handleClickNewRecord = () => {
  //   if (checkDisplayRecord) {
  //     dispatch(getRewardRecord({ raceId, horseId }))
  //   }
  //   setCheckDisplayRecord(!checkDisplayRecord)
  // }
  
  return (
    <Modal onOverlayClick={onClose}>
      <JackpotDetailStyle width={width} height={height}>
        <button disabled={loadingModal} className='close-btn p-0 position-absolute' role='button' onClick={onClose}>
          <MemoizedImageHorsePopupClose />
        </button>
        {loadingModal ? (
          <div className='loading__item'>
            <Spin />
          </div>
        ) : (
          <div className='content-box'>
            <div className='information-box'>
              <div className='attribute active'>
                Pool Reward
                <img src={LINE_TEXT} alt='' className='line-text' />
              </div>
            </div>
            <div className='content_jackpot color-yellow margin_top_15'>
              {jackpot.poolReward.price ? <>
                { jackpot.poolReward.price }
                <div className='img_pool_reward'>
                  <MemoizedLoadImageCommon srcImg={jackpot.poolReward.icon} />
                </div>
              </> : "---"}
            </div>

            {/* Jackpot */}
            <div className='information-box'>
              {totalArmor || totalSkin || totalItem || totalKuda || totalMerah || totalAdil ? (
                <>
                  <div className='img_jackpot'>
                    <MemoizedLoadImageCommon srcImg={JACKPOT_ANIM} />
                  </div>
                  <div className='description active'>
                    Jackpot Reward
                    <img src={LINE_TEXT} alt='' className='line-text' />
                  </div>
                </>
              ) : ""}
            </div>
            {/* {checkDisplay ? (
              <>
                <div className='content_jackpot color-yellow'>
                  {totalArmor ? <div className='content_armor color-yellow'>
                    <div className='img_armor'>
                      <MemoizedLoadImageCommon srcImg={ARMOR_ICON} />
                    </div>
                    { 'x' }
                    { totalArmor }
                  </div> : ""}
                  {totalSkin ? <div className='content_armor color-yellow'>
                    <div className='img_armor'>
                      <MemoizedLoadImageCommon srcImg={SKIN_ICON} />
                    </div>
                    { 'x' }
                    { totalSkin }
                  </div> : ""}
                  {totalItem ? <div className='content_armor color-yellow'>
                    <div className='img_armor img_doping'>
                      <MemoizedLoadImageCommon srcImg={VIEW_DOPING} />
                    </div>
                    { 'x' }
                    { totalItem }
                  </div> : ""}
                </div>
                <div className='content_jackpot color-yellow'>
                  {totalKuda ? <div className='content_armor color-yellow'>
                    <div className='img_armor'>
                      <MemoizedLoadImageCommon srcImg={GAME_TOKEN_KUDA} />
                    </div>
                    { '+' }
                    { numberWithCommas(totalKuda) }
                  </div> : ""}
                  {totalMerah ? <div className='content_armor color-yellow'>
                    <div className='img_armor'>
                      <MemoizedLoadImageCommon srcImg={GAME_TOKEN_MERAH} />
                    </div>
                    { '+' }
                    { numberWithCommas(totalMerah) }
                  </div> : ""}
                  {totalAdil ? <div className='content_armor color-yellow'>
                    <div className='img_armor'>
                      <MemoizedLoadImageCommon srcImg={ICON_ADIL} />
                    </div>
                    { '+' }
                    { numberWithCommas(totalAdil) }
                  </div> : ""}
                </div>
              </>
            ) : ( */}
              {checkReward ? <div className='content_jackpot_detail'>
                {getRewardJackpotSelector?.items?.length ? getRewardJackpotSelector?.items?.map((item: any, index: any) => {
                  return <div key={index} className='row_jackpot_detail'>
                    <div className='image_jackpot'>
                      <MemoizedLoadImageCommon srcImg={item?.img} />
                    </div>
                    <div>
                      <div className='name_jackpot'>
                        <span className='title_name'>Name: </span>
                        {item?.name}
                      </div>
                      <div className='rarity_jackpot'>
                        <span className='title_rarity'>Rarity:</span>
                        <span className='image_rarity'>{ showIconRariTyCommon(item?.rarity_type) }</span>
                      </div>
                    </div>
                  </div>
                }) : ""}

                {getRewardJackpotSelector?.boosters?.length ? getRewardJackpotSelector?.boosters?.map((item: any, index: any) => {
                  return <div key={index} className='row_jackpot_detail'>
                    <div className='image_jackpot padding_img'>
                      <div className='boder_img'>
                        <MemoizedLoadImageCommon srcImg={item?.img} />
                      </div>
                    </div>
                    <div>
                      <div className='name_jackpot'>
                        <span className='title_name'>Name: </span>
                        {item?.name}
                      </div>
                      <div className='rarity_jackpot'>
                        <span className='title_rarity'>Amount:</span>
                        <span>{item?.amount}</span>
                      </div>
                    </div>
                  </div>
                }) : ""}
                
                {getRewardJackpotSelector?.coins?.length ? getRewardJackpotSelector?.coins?.map((item: any, index: any) => {
                  let imgCoin = ""
                  switch (item?.item_type) {
                    case "ADIL": {
                      imgCoin = ICON_ADIL
                      break;
                    }
                    case "MERAH_NEW": {
                      imgCoin = GAME_TOKEN_MERAH
                      break;
                    }
                    case "KUDA_NEW": {
                      imgCoin = GAME_TOKEN_KUDA
                      break;
                    }
                  
                    default:
                      break;
                  }
                  return <div key={index} className='row_jackpot_detail'>
                    <div className='image_jackpot padding_img_coin'>
                      <MemoizedLoadImageCommon srcImg={imgCoin} />
                    </div>
                    <div>
                      <div className='rarity_jackpot'>
                        <span className='title_rarity'>Amount:</span>
                        <span>{numberWithCommas(item?.amount)}</span>
                      </div>
                    </div>
                  </div>
                }) : ""}
              </div> : 
              <div className='non_reward'>
                { "Rewards are currently being processed." }
                <br/>
                { "Please wait a moment while we complete the transaction." }
              </div>}
            {/* )} */}

            {/* New Record */}
            <div className='information-box'>
              {totalArmorRecord || totalSkinRecord || totalItemRecord || totalKudaRecord || totalMerahRecord || totalAdilRecord ? (
                <>
                  <div className='img_new_record'>
                    <MemoizedLoadImageCommon srcImg={NEW_RECORD} />
                  </div>
                  <div className='attribute active'>
                    New Record Reward
                    <img src={LINE_TEXT} alt='' className='line-text' />
                  </div>
                </>
              ) : ""}
            </div>
            {/* {checkDisplayRecord ? (
              <>
                <div className='content_jackpot color-yellow'>
                  {totalArmorRecord ? <div className='content_armor color-yellow'>
                    <div className='img_armor'>
                      <MemoizedLoadImageCommon srcImg={ARMOR_ICON} />
                    </div>
                    { 'x' }
                    { totalArmorRecord }
                  </div> : ""}
                  {totalSkinRecord ? <div className='content_armor color-yellow'>
                    <div className='img_armor'>
                      <MemoizedLoadImageCommon srcImg={SKIN_ICON} />
                    </div>
                    { 'x' }
                    { totalSkinRecord }
                  </div> : ""}
                  {totalItemRecord ? <div className='content_armor color-yellow'>
                    <div className='img_armor img_doping'>
                      <MemoizedLoadImageCommon srcImg={VIEW_DOPING} />
                    </div>
                    { 'x' }
                    { totalItemRecord }
                  </div> : ""}
                </div>
                <div className='content_jackpot color-yellow'>
                  {totalKudaRecord ? <div className='content_armor color-yellow'>
                    <div className='img_armor'>
                      <MemoizedLoadImageCommon srcImg={GAME_TOKEN_KUDA} />
                    </div>
                    { '+' }
                    { numberWithCommas(totalKudaRecord) }
                  </div> : ""}
                  {totalMerahRecord ? <div className='content_armor color-yellow'>
                    <div className='img_armor'>
                      <MemoizedLoadImageCommon srcImg={GAME_TOKEN_MERAH} />
                    </div>
                    { '+' }
                    { numberWithCommas(totalMerahRecord) }
                  </div> : ""}
                  {totalAdilRecord ? <div className='content_armor color-yellow'>
                    <div className='img_armor'>
                      <MemoizedLoadImageCommon srcImg={ICON_ADIL} />
                    </div>
                    { '+' }
                    { numberWithCommas(totalAdilRecord) }
                  </div> : ""}
                </div>
              </>
              ) : ( */}
                {checkRewardRecord ? <div className='content_jackpot_detail'>
                  {getNewRecordSelector?.items?.length ? getNewRecordSelector?.items?.map((item: any, index: any) => {
                    return <div key={index} className='row_jackpot_detail'>
                      <div className='image_jackpot'>
                        <MemoizedLoadImageCommon srcImg={item?.img} />
                      </div>
                      <div>
                        <div className='name_jackpot'>
                          <span className='title_name'>Name: </span>
                          {item?.name}
                        </div>
                        <div className='rarity_jackpot'>
                          <span className='title_rarity'>Rarity:</span>
                          <span className='image_rarity'>{ showIconRariTyCommon(item?.rarity_type) }</span>
                        </div>
                      </div>
                    </div>
                  }) : ""}
  
                  {getNewRecordSelector?.boosters?.length ? getNewRecordSelector?.boosters?.map((item: any, index: any) => {
                    return <div key={index} className='row_jackpot_detail'>
                      <div className='image_jackpot padding_img'>
                        <div className='boder_img'>
                          <MemoizedLoadImageCommon srcImg={item?.img} />
                        </div>
                      </div>
                      <div>
                        <div className='name_jackpot'>
                          <span className='title_name'>Name: </span>
                          {item?.name}
                        </div>
                        <div className='rarity_jackpot'>
                          <span className='title_rarity'>Amount:</span>
                          <span>{item?.amount}</span>
                        </div>
                      </div>
                    </div>
                  }) : ""}
                  
                  {getNewRecordSelector?.coins?.length ? getNewRecordSelector?.coins?.map((item: any, index: any) => {
                    let imgCoin = ""
                    switch (item?.item_type) {
                      case "ADIL": {
                        imgCoin = ICON_ADIL
                        break;
                      }
                      case "MERAH_NEW": {
                        imgCoin = GAME_TOKEN_MERAH
                        break;
                      }
                      case "KUDA_NEW": {
                        imgCoin = GAME_TOKEN_KUDA
                        break;
                      }
                    
                      default:
                        break;
                    }
                    return <div key={index} className='row_jackpot_detail'>
                      <div className='image_jackpot padding_img_coin'>
                        <MemoizedLoadImageCommon srcImg={imgCoin} />
                      </div>
                      <div>
                        <div className='rarity_jackpot'>
                          <span className='title_rarity'>Amount:</span>
                          <span>{numberWithCommas(item?.amount)}</span>
                        </div>
                      </div>
                    </div>
                  }) : ""}
                </div> : 
                <div className='non_reward'>
                  { "Rewards are currently being processed." }
                  <br/>
                  { "Please wait a moment while we complete the transaction." }
                </div>}
              {/* )} */}
          </div>
        )}
      </JackpotDetailStyle>
    </Modal>
  )
}

export default ModalJackpotDetail
