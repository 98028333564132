import { BOARD_VIEW_TIME } from 'assets/images'
import styled from 'styled-components'

const BoardViewTimeStyled = styled.div`
  .board-view-time {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(${BOARD_VIEW_TIME});
    ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
      background-image: none;
    }
    width: 100%;
    height: 170px;
    ${({ theme }) => theme.media.lessThan(theme.size.xl)} {
      left: -30px;
    }
    background-repeat: no-repeat;
    background-position: center;

    .display-time {
      color: ${({ theme }) => theme.color.white};
      line-height: 48px;
      font-size: 40px;
      display: flex;
    }

    .hour-min-sec {
      color: #828795;
      line-height: 14.4px;
      font-size: 12px;
      display: flex;
    }

    .display-time, .hour-min-sec {
      span {
        min-width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .processing-race {
      font-size: 40px;
      line-height: 48px;
      color: ${({ theme }) => theme.color.white};
    }

    .orange-line-lean {
      display: flex;
      .orange-line-lean-left {
        display: flex;
        margin-top: 10px;
      }

      .orange-line-lean-right {
        display: flex;
        margin-top: 10px;
      }

      .polygon {
        padding-left: 20.94px;
        padding-right: 20.94px;
        margin-top: 5px;
      }
    }
  }
`
export default BoardViewTimeStyled
