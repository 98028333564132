import styled from 'styled-components'

interface MyHorseItemStyled {
  mySelf: boolean
}

const MyHorseItemStyled = styled.tr<MyHorseItemStyled>`
  clip-path: polygon(0 0, 100% 0, 100% 100%, 14px 100%, 0 calc(100% - 14px));
  vertical-align: middle;
  background-color: rgb(37, 43, 67) !important;

  cursor: pointer;
  td {
    font-size: 16px;
    line-height: 20px;
    color: ${({ mySelf }) => (mySelf ? 'rgb(78, 240, 118)' : 'white')};

    p {
      color: ${({ mySelf }) => (mySelf ? 'rgb(78, 240, 118)' : 'white')};
    }

    .horse-avatar {
      width: 50px;
    }

    .horse-class {
      top: 4px;
    }
  }

  .width-3 {
    width: 3%;
  }

  .width-5 {
    width: 5%;
  }

  .width-8 {
    width: 8%;
    text-align: center;
  }

  .width-10 {
    width: 10%;
  }

  .width-15 {
    width: 15%;
  }

  .width-15-name {
    width: 15%;
    text-align: left;
  }

  .width-18 {
    width: 18%;
  }

  .width-20 {
    width: 20%;
    text-align: left;
  }

  .width-20px {
    width: 20px;
  }

  .width-21 {
    width: 21%;
  }

  .width-25 {
    width: 25%;
    text-align: left;
  }

  .padding-left-name {
    padding-left: 7.5%;
  }

  .padding-left {
    padding-left: 7.5%;
  }

  .padding-left-5 {
    padding-left: 5%;
  }

  .padding-left-4 {
    padding-left: 4%;
  }

  .padding-left-59 {
    padding-left: 5.8%;
  }

  .padding-left-6 {
    padding-left: 6.4%;
  }

  .stt {
    height: 52px;

    img {
      margin-right: 10px;
    }
  }

  .gender {
    text-transform: lowercase;
  }
  .gender:first-line {
    text-transform: capitalize;
  }
  .horse-name {
    justify-content: center;
    align-items: center;
  }
  .horse-name-content {
    /* width: 250px; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    .background {
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      background-size: contain;
      width: 50px;
      ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
        background-size: cover;
      }
  
      .avatar {
        width: 50px;
        aspect-ratio: 300 / 300;
        object-fit: contain;
        object-position: center;
  
        ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
          width: 100%;
        }
      }
  
      .item {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
  
        &.head {
          z-index: 2;
          width: 25px;
          height: 25px;
        }
  
        &.body {
          z-index: 3;
          height: 50px;
          top: 50%;
          transform: translateY(-50%);
        }
  
        &.foot {
          z-index: 4;
          width: 50px;
          height: auto;
          left: 0%;
          bottom: 0%;
          top: unset;
        }
  
        &.skin {
          z-index: 1;
        }
  
        &.shoes{
          width: 50px;
          height: auto;
          left: 0%;
          bottom: 0%;
          top: unset;
          z-index: 3;
        }
        &.glass{
          height: auto;
          width: 25px;
          top: 0%;
          left: 0%;
          z-index: 3;
        }
      }
    }

    .horse__name {
      /* max-width: 145px; */
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      text-align: left;
      padding-left: 10px;
    }
  }

  .horse-gender {
    width: 35px !important;
    display: flex;

    img {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .region {
    width: 25px;
    height: 25px;
    display: flex;

    img {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .center {
    text-align: center;
  }

  .vip__pass {
    div {
      margin-left: 0px;
    }
  }

  .img_new_record {
    width: 50%;
    height: auto;
    padding-top: 5px;
  }

  /* .owner {
    padding-left: 75px;
  } */

  /* .care {
    padding-left: 100px;
  } */

  /* .race {
    padding-left: 80px;
  } */
`

export default MyHorseItemStyled
