/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
interface Column {
  Header: string
  accessor: any
}

interface Accessor {
  count_down: string
  raceName: string
  raceCourse: string
  grade: string
  field: string
  distance: string
  entryFee: string
  totalPrize: string
  totalPrizeT: string,
  registered: string
  startIn: string
  gate: string
  horse: string
  gender: string
  region: string
  isNewRecord: string
  horseNameArmorSkin: string
  highlight: string
  bloodLine: string
  statistic: string
  ownerName: string
  weaponItemsEquipped: any
  startAt: string
  endAt: string
  racePosition: string
  racePrize: string
  experienceReceived: string
  cancelCountDown: string
  avatar: string
  getEntryFee: string
  raceNo: string
  recervedPrize: string
  total_rented_horse: string
  weather: string
  raceType: string
  jackpot: string
  guildJackpot: string
}

export const accessor: Accessor = {
  count_down: 'count_down',
  raceName: 'name',
  raceCourse: 'course.name',
  grade: 'racing_class_name',
  field: 'field_type',
  distance: 'distance',
  entryFee: 'entry_fee',
  totalPrize: 'total_prize',
  totalPrizeT: 'totalPrizeT',
  registered: 'registered',
  startIn: 'start_at',
  gate: 'gate',
  horse: 'horse',
  gender: 'gender',
  region: 'region',
  isNewRecord: 'isNewRecord',
  horseNameArmorSkin: 'horseNameArmorSkin',
  highlight: 'highlight',
  bloodLine: 'blood_line',
  statistic: 'statistic',
  ownerName: 'owner_name',
  weaponItemsEquipped: 'weaponItemsEquipped',
  startAt: 'start_at',
  endAt: 'end_at',
  racePosition: 'race_position',
  racePrize: 'race_prize',
  experienceReceived: 'experience_received',
  cancelCountDown: 'cancel_count_down',
  avatar: 'avatar',
  getEntryFee: 'getEntryFee',
  raceNo: 'race_no',
  recervedPrize: 'received_prize',
  total_rented_horse: 'total_rented_horse',
  weather: 'weather',
  raceType: 'race_type',
  jackpot: 'jackpot',
  guildJackpot: 'guildJackpot'
}

const gateColumn: Column = {
  Header: 'gate no.',
  accessor: accessor.gate
}

const horseColumn: Column = {
  Header: 'horse',
  accessor: accessor.horse
}

const openGenderColumn: Column = {
  Header: '',
  accessor: accessor.gender
}

const openRegionColumn: Column = {
  Header: '',
  accessor: accessor.region
}

const isNewRecordColumn: Column = {
  Header: '',
  accessor: accessor.isNewRecord
}

const horseNameArmorSkinColumn: Column = {
  Header: 'horse',
  accessor: accessor.horseNameArmorSkin
}

const horseInfoColumn: Column = {
  Header: 'bloodline',
  accessor: accessor.bloodLine
}

const statisticColumn: Column = {
  Header: 'career',
  accessor: accessor.statistic
}

const ownerNameColumn: Column = {
  Header: 'owner name',
  accessor: accessor.ownerName
}

const armorSkinColumn: Column = {
  Header: 'Armor-Skin',
  accessor: accessor.weaponItemsEquipped
}

const highlightColumn: Column = {
  Header: '',
  accessor: accessor.highlight
}

const rankColumn: Column = {
  Header: 'rank',
  accessor: accessor.racePosition
}

const totalPrizeColumn: Column = {
  Header: 'prize',
  accessor: accessor.totalPrize
}

// const totalPrizeTColumn: Column = {
//   Header: 'prize',
//   accessor: accessor.totalPrizeT
// }

const jackpotColumn: Column = {
  Header: 'jackpot',
  accessor: accessor.jackpot
}

const racePrizeColumn: Column = {
  Header: 'prize',
  accessor: accessor.racePrize
}

const expColumn: Column = {
  Header: 'exp',
  accessor: accessor.experienceReceived
}

const raceNameColumn: Column = {
  Header: 'race name',
  accessor: accessor.raceName
}

const raceCourseColumn: Column = {
  Header: 'racecourse',
  accessor: accessor.raceCourse
}

const gradeCourseColumn: Column = {
  Header: 'class',
  accessor: accessor.grade
}

const fieldTypeColumn: Column = {
  Header: 'field type',
  accessor: accessor.field
}

const distanceColumn: Column = {
  Header: 'distance',
  accessor: accessor.distance
}

const entryFeeColumn: Column = {
  Header: 'entry fee',
  accessor: accessor.entryFee
}

const registeredColumn: Column = {
  Header: 'registered',
  accessor: accessor.registered
}

const weatherColumn: Column = {
  Header: '',
  accessor: accessor.weather
}

const startAtColumn: Column = {
  Header: 'date',
  accessor: accessor.endAt
}

const startsInColumn: Column = {
  Header: 'starts in',
  accessor: accessor.count_down
}

const cancelRaceHorseColumn: Column = {
  Header: '',
  accessor: accessor.cancelCountDown
}

const raceStatusColumn: Column = {
  Header: 'race status',
  accessor: accessor.count_down
}

const raceNoColumn: Column = {
  Header: 'race no',
  accessor: accessor.raceNo
}

const raceTypeColumn: Column = {
  Header: 'Race type',
  accessor: accessor.raceType
}

export const openAndSchedulingDetailColumns: Column[] = [
  gateColumn,
  horseNameArmorSkinColumn,
  openGenderColumn,
  openRegionColumn,
  isNewRecordColumn,
  horseInfoColumn,
  statisticColumn,
  ownerNameColumn,
  cancelRaceHorseColumn,
  highlightColumn
]
export const openDetailColumns: Column[] = [
  gateColumn,
  horseColumn,
  openGenderColumn,
  openRegionColumn,
  isNewRecordColumn,
  horseInfoColumn,
  statisticColumn,
  ownerNameColumn,
  armorSkinColumn,
  cancelRaceHorseColumn
]

export const resultDetailColumns: Column[] = [
  rankColumn,
  gateColumn,
  horseNameArmorSkinColumn,
  openGenderColumn,
  openRegionColumn,
  isNewRecordColumn,
  horseInfoColumn,
  racePrizeColumn,
  expColumn,
  ownerNameColumn,
  highlightColumn
]

export const openListColumns: Column[] = [
  raceNameColumn,
  raceCourseColumn,
  gradeCourseColumn,
  fieldTypeColumn,
  distanceColumn,
  raceTypeColumn,
  entryFeeColumn,
  totalPrizeColumn,
  registeredColumn,
  weatherColumn
]

export const resultListColumns: Column[] = [
  raceNameColumn,
  jackpotColumn,
  raceCourseColumn,
  gradeCourseColumn,
  fieldTypeColumn,
  distanceColumn,
  raceTypeColumn,
  entryFeeColumn,
  totalPrizeColumn,
  startAtColumn,
  weatherColumn
]

export const schedulingListColumns: Column[] = [
  raceNameColumn,
  jackpotColumn,
  raceCourseColumn,
  gradeCourseColumn,
  fieldTypeColumn,
  distanceColumn,
  raceTypeColumn,
  totalPrizeColumn,
  startsInColumn,
  weatherColumn
]

// profit
interface Profit {
  horse_name: string
  prize_for_owner: string
  rent_days: string
  rent_fee: string
  renter_name: string
  total: string
  no: string
}
export const profitColumns: Profit = {
  horse_name: 'horse_name',
  rent_days: 'rent_days',
  rent_fee: 'rent_fee',
  renter_name: 'renter_name',
  prize_for_owner: 'prize_for_owner',
  no: 'No',
  total: 'total'
}

const gateNoColumn: Column = {
  Header: 'NO',
  accessor: profitColumns.no
}

const noColumn: Column = {
  Header: '',
  accessor: profitColumns.no
}

const horseNameColumn: Column = {
  Header: 'HORSE NAME',
  accessor: profitColumns.horse_name
}
const renterNameColumn: Column = {
  Header: 'BORROWED ADDRESS',
  accessor: profitColumns.renter_name
}
const rentFeeNoColumn: Column = {
  Header: 'PROFIT',
  accessor: profitColumns.rent_fee
}
const totalColumn: Column = {
  Header: 'TOTAL',
  accessor: profitColumns.prize_for_owner
}

export const profitListColumns: Column[] = [
  gateNoColumn,
  horseNameColumn,
  renterNameColumn,
  rentFeeNoColumn,
  totalColumn
]

//end profit

// start: special race
interface Special {
  horseInfo: string
  specialDopping: string
  specialRegion: string
  inDate: string
  chooseToRace: string
  putToFarm: string
  career: string
  EventId: string
  totalHorse: string
  totalHorseInRace: string
  totalRace: string
  eventOpenTime: string
  eventEndTime: string
  chooseToRaceVirtual: string
  weather: string
  horse_gender: string
  region: string
  specialNewRecord: string
}

export const specialRace: Special = {
  horseInfo: 'horseInfo',
  specialDopping: 'specialDopping',
  specialRegion: 'specialRegion',
  inDate: 'in_stable_date',
  chooseToRace: 'real_start_at',
  putToFarm: 'in_stable_date',
  career: 'career',
  EventId: 'id',
  totalHorse: 'total_horse',
  totalHorseInRace: 'total_horse_in_race',
  totalRace: 'total_race',
  eventOpenTime: 'event_open_time',
  eventEndTime: 'event_end_time',
  chooseToRaceVirtual: 'choose_to_race_virtual',
  weather: 'weather',
  horse_gender: 'horse_gender',
  region: 'region',
  specialNewRecord: 'specialNewRecord',
}
const specialHorseColumn: Column = {
  Header: 'HORSE',
  accessor: specialRace.horseInfo
}
const specialDoppingColumn: Column = {
  Header: '',
  accessor: specialRace.specialDopping
}

const specialNewRecordColumn: Column = {
  Header: '',
  accessor: specialRace.specialNewRecord
}

const specialRegionColumn: Column = {
  Header: 'Region',
  accessor: specialRace.specialRegion
}
// const horseInDateColumn: Column = {
//   Header: 'Date',
//   accessor: specialRace.inDate
// }

const chooseToRaceColumn: Column = {
  Header: 'race info',
  accessor: specialRace.chooseToRace
}

const chooseToRaceSelectionColumn: Column = {
  Header: 'race info',
  accessor: specialRace.chooseToRaceVirtual
}

const putToFarmColumn: Column = {
  Header: 'enter farm at',
  accessor: specialRace.putToFarm
}

const totalHorseColumn: Column = {
  Header: 'horse total',
  accessor: specialRace.totalHorse
}

const careerColumn: Column = {
  Header: 'career',
  accessor: specialRace.career
}

const eventIdColumn: Column = {
  Header: 'no.',
  accessor: specialRace.EventId
}

const selectHorseColumn: Column = {
  Header: 'selected',
  accessor: specialRace.totalHorseInRace
}

const totalRaceColumn: Column = {
  Header: 'race total',
  accessor: specialRace.totalRace
}

const eventCreateColumn: Column = {
  Header: 'open at',
  accessor: specialRace.eventOpenTime
}

const eventEndColumn: Column = {
  Header: 'end at',
  accessor: specialRace.eventEndTime
}

const specialRaceWeatherColumn: Column = {
  Header: '',
  accessor: specialRace.weather
}

const specialRaceGenderColumn: Column = {
  Header: '',
  accessor: specialRace.horse_gender
}

const specialRaceRegionColumn: Column = {
  Header: '',
  accessor: specialRace.region
}

export const openListEventColumns: Column[] = [
  raceNoColumn,
  specialRaceWeatherColumn,
  raceNameColumn,
  jackpotColumn,
  raceCourseColumn,
  gradeCourseColumn,
  fieldTypeColumn,
  distanceColumn,
  raceTypeColumn,
  raceStatusColumn
]

export const specialHorseColumns: Column[] = [
  specialHorseColumn,
  specialRaceGenderColumn,
  specialRaceRegionColumn,
  specialDoppingColumn,
  specialNewRecordColumn,
  careerColumn,
  ownerNameColumn,
  rankColumn,
  chooseToRaceColumn,
  putToFarmColumn
]

export const specialHorseSelectionColumns: Column[] = [
  specialHorseColumn,
  specialRaceGenderColumn,
  specialRaceRegionColumn,
  specialDoppingColumn,
  specialNewRecordColumn,
  careerColumn,
  ownerNameColumn,
  rankColumn,
  chooseToRaceSelectionColumn,
  putToFarmColumn
]

export const eventHistoryColumns: Column[] = [
  eventIdColumn,
  totalHorseColumn,
  selectHorseColumn,
  totalRaceColumn,
  eventCreateColumn,
  eventEndColumn
]

// end: special race

// start: guild
interface Guild {
  avatar: string
  guildName: string
  guildLevel: string
  exp: string
  member: string
  career: string
  chooseToGuild: string
  no: string
  status: string
  winRate: string
  position: string
  manage: string
  disabled: string
  farmHorse: string
  role: string
  horse_in_guild?: string
  raceName: string
  totalHorse: string
  guildMaster: string
  guildHorse: string
  guildVipPass: string
  guildVipPassBlackList: string
  guildDopping: string
  guildNewRecord: string
  guildDoppingBlackList: string
  renterName: string
  memberGuildList: string
  guildHorseBlackList: string
  total_rented_horse: string
  guildTag: string
  approve: string
  cancel: string
  gender: string
  guildGender: string
  guildRegion: string
}

export const guild: Guild = {
  avatar: 'avatar',
  guildName: 'guildName',
  guildLevel: 'guildLevel',
  exp: 'exp',
  member: 'member',
  career: 'career',
  chooseToGuild: 'chooseToGuild',
  no: 'no',
  status: 'status',
  winRate: 'winRate',
  position: 'position',
  manage: 'manage',
  disabled: 'disabled',
  farmHorse: 'farmHorse',
  role: 'role',
  horse_in_guild: 'horse_in_guild',
  raceName: 'raceName',
  totalHorse: 'totalHorse',
  guildMaster: 'guildMaster',
  guildHorse: 'guildHorse',
  guildVipPass: 'guildVipPass',
  guildVipPassBlackList: 'guildVipPassBlackList',
  guildDopping: 'guildDopping',
  guildNewRecord: 'guildNewRecord',
  guildDoppingBlackList: 'guildDoppingBlackList',
  renterName: 'renterName',
  memberGuildList: 'memberGuildList',
  guildHorseBlackList: 'guildHorseBlackList',
  total_rented_horse: 'total_rented_horse',
  guildTag: 'guildTag',
  approve: 'approve',
  cancel: 'cancel',
  gender: 'gender',
  guildGender: 'guildGender',
  guildRegion: 'guildRegion'
}
const guildAvatarColumn: Column = {
  Header: '',
  accessor: guild.avatar
}

const guildNameColumn: Column = {
  Header: 'Guild Name',
  accessor: guild.guildName
}
// const guildLevelColumn: Column = {
//   Header: 'Guild Level',
//   accessor: guild.guildLevel
// }
// const guildExpColumn: Column = {
//   Header: 'Exp',
//   accessor: guild.exp
// }

// const guildMemberColumn: Column = {
//   Header: 'member',
//   accessor: guild.member
// }

const guildListMemberColumn: Column = {
  Header: 'member',
  accessor: guild.memberGuildList
}

const guildMemberNameColumn: Column = {
  Header: 'member name',
  accessor: guild.member
}

const guildUserNameColumn: Column = {
  Header: 'user name',
  accessor: guild.member
}

const guildMasterNameColumn: Column = {
  Header: 'guild master',
  accessor: guild.guildMaster
}

const guildHorseColumn: Column = {
  Header: 'rented guild horse',
  accessor: guild.total_rented_horse
}

const guildTotalHorseColumn: Column = {
  Header: 'horse',
  accessor: guild.totalHorse
}

const guildCareerColumn: Column = {
  Header: 'career',
  accessor: guild.career
}
// const chooseToGuildColumn: Column = {
//   Header: ' ',
//   accessor: guild.chooseToGuild
// }

// const disabledColumn: Column = {
//   Header: ' ',
//   accessor: guild.disabled
// }

const statusColumn: Column = {
  Header: 'status',
  accessor: guild.status
}
const winRateColumn: Column = {
  Header: 'win rate',
  accessor: guild.winRate
}
// const positionColumn: Column = {
//   Header: 'position',
//   accessor: guild.position
// }

const manageColumn: Column = {
  Header: 'action',
  accessor: guild.manage
}

const approveColumn: Column = {
  Header: '',
  accessor: guild.approve
}

const cancelColumn: Column = {
  Header: '',
  accessor: guild.cancel
}

const guildFarmHorseColumn: Column = {
  Header: 'guild farm horse',
  accessor: guild.farmHorse
}

const roleColumn: Column = {
  Header: 'role',
  accessor: guild.role
}

const raceDateColumn: Column = {
  Header: 'race date',
  accessor: accessor.endAt
}
const raceNameGuildColumn: Column = {
  Header: 'race name',
  accessor: guild.raceName
}

const guildFieldTypeColumn: Column = {
  Header: 'field',
  accessor: accessor.field
}

const guildEntryFeeColumn: Column = {
  Header: 'entry',
  accessor: accessor.entryFee
}

const recervedPrizeColumn: Column = {
  Header: `received <br> prize`,
  accessor: accessor.recervedPrize
}

const guildHorseInforColumn: Column = {
  Header: 'Horse Name',
  accessor: guild.guildHorse
}

const guildVipPassColumn: Column = {
  Header: '',
  accessor: guild.guildVipPass
}

const guildVipPassBlackListColumn: Column = {
  Header: '',
  accessor: guild.guildVipPassBlackList
}

const guildDoppingColumn: Column = {
  Header: '',
  accessor: guild.guildDopping
}

const guildNewRecordColumn: Column = {
  Header: '',
  accessor: guild.guildNewRecord
}

const guildDoppingBlackListColumn: Column = {
  Header: '',
  accessor: guild.guildDoppingBlackList
}

const guildHorseBlackListColumn: Column = {
  Header: 'Horse Name',
  accessor: guild.guildHorseBlackList
}

const rentedNameColumn: Column = {
  Header: 'Rented member',
  accessor: guild.renterName
}

const guildHorseNameColumn: Column = {
  Header: 'Horse Name',
  accessor: specialRace.horseInfo
}

const guildTagColumn: Column = {
  Header: 'Guild',
  accessor: guild.guildTag
}

const guildGenderColumn: Column = {
  Header: '',
  accessor: guild.guildGender
}

const guildRegionColumn: Column = {
  Header: '',
  accessor: guild.guildRegion
}

const guildJackpotColumn: Column = {
  Header: 'jackpot',
  accessor: accessor.guildJackpot
}

export const guildSelectionColumns: Column[] = [
  noColumn,
  guildAvatarColumn,
  guildNameColumn,
  // guildLevelColumn,
  // guildExpColumn,
  // guildMemberColumn,
  guildListMemberColumn,
  guildTotalHorseColumn,
  guildCareerColumn,
  winRateColumn,
  guildMasterNameColumn
  // chooseToGuildColumn,
  // disabledColumn
]

export const guildFarmColumns: Column[] = [
  gateNoColumn,
  guildHorseInforColumn,
  guildGenderColumn,
  guildRegionColumn,
  guildVipPassColumn,
  guildDoppingColumn,
  guildNewRecordColumn,
  gradeCourseColumn,
  guildCareerColumn,
  winRateColumn,
  rentedNameColumn,
  statusColumn
]

export const guildMemberColumns: Column[] = [
  gateNoColumn,
  guildAvatarColumn,
  guildMemberNameColumn,
  guildHorseColumn,
  guildCareerColumn,
  winRateColumn,
  roleColumn,
  manageColumn
]

export const guildMemberColumns2: Column[] = [
  gateNoColumn,
  guildAvatarColumn,
  guildMemberNameColumn,
  guildHorseColumn,
  guildCareerColumn,
  winRateColumn,
  roleColumn
]

export const guildYourHorseColumns: Column[] = [
  gateNoColumn,
  specialHorseColumn,
  guildDoppingColumn,
  guildCareerColumn,
  gradeCourseColumn,
  ownerNameColumn,
  statusColumn
]

export const guildHorseOwnerColumns: Column[] = [
  gateNoColumn,
  guildAvatarColumn,
  guildMemberNameColumn,
  guildFarmHorseColumn,
  guildCareerColumn,
  winRateColumn,
  roleColumn
]

export const guildRaceHistory: Column[] = [
  gateNoColumn,
  // guildHorseNameColumn,
  raceNameGuildColumn,
  guildJackpotColumn,
  gradeCourseColumn,
  guildFieldTypeColumn,
  distanceColumn,
  raceTypeColumn,
  rankColumn,
  guildEntryFeeColumn,
  totalPrizeColumn,
  recervedPrizeColumn,
  raceDateColumn
]

export const guildMyHorseColumns: Column[] = [
  noColumn,
  guildHorseInforColumn,
  guildGenderColumn,
  guildRegionColumn,
  guildVipPassColumn,
  guildDoppingColumn,
  guildNewRecordColumn,
  gradeCourseColumn,
  guildCareerColumn,
  winRateColumn,
  rentedNameColumn,
  guildTagColumn,
  statusColumn
]

export const guildApplicationColumns: Column[] = [
  gateNoColumn,
  guildAvatarColumn,
  guildUserNameColumn,
  guildCareerColumn,
  winRateColumn,
  approveColumn
]

export const guildUserBlacklistColumns: Column[] = [
  gateNoColumn,
  guildAvatarColumn,
  guildUserNameColumn,
  guildCareerColumn,
  winRateColumn,
  cancelColumn
]

export const guildHorseBlacklistColumns: Column[] = [
  gateNoColumn,
  // guildHorseInforColumn,
  guildHorseBlackListColumn,
  guildGenderColumn,
  guildRegionColumn,
  guildNewRecordColumn,
  guildVipPassBlackListColumn,
  guildDoppingBlackListColumn,
  gradeCourseColumn,
  guildCareerColumn,
  winRateColumn,
  cancelColumn
]

// end: guild
