import styled from 'styled-components'

import { OPEN_RACE_BTN_BORDER } from 'assets/images'

interface RaceTableStyled {
  isRowClickable: boolean
}

const enterBtnDimensionRatio = 3.235

const RaceTableStyled = styled.div<RaceTableStyled>`
  .race-table-container {
    .race-table {
      ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
        overflow-x: scroll;
      }

      .no-data {
        font-weight: 800;
        background-color: #191d2c;
        padding: 20px;
        color: #ed842a;
        text-align: center;
        text-transform: uppercase;
      }

      .loading {
        color: ${({ theme }) => theme.color.grey};
        height: 50px;
        width: 50px;
      }

      .table {
        min-width: 936px;
        border-collapse: separate;
        border-spacing: 0px 8px;

        > :not(caption) > * > * {
          padding: 0.5rem 0;
        }

        .table-head {
          border-bottom: 2px solid ${({ theme }) => theme.color.black};

          .table-row {
            .th {
              color: ${({ theme }) => theme.color.grey};
              font-size: 18px;
              line-height: 20px;
              padding: 0.5rem 0.3rem;
            }
          }
        }

        .table-body {
          border-top: none;
          .used-doping{
            width: 40px;
          }
          &.highlight tr {
            background-color: ${({ theme }) => theme.color.neutralOpacity};
          }

          &.highlight tr:nth-child(1) {
            background-color: #3a342f;
          }

          &.highlight tr:nth-child(2) {
            background-color: #2a2a2a;
          }

          &.highlight tr:nth-child(3) {
            background-color: #232128;
          }

          .table-row td:first-child {
            padding-left: 16px;
          }

          .table-row {
            border-color: ${({ theme }) => theme.color.transparent};
            height: 50px;
            position: relative;
            background-color: ${({ theme }) => theme.color.neutral};
            cursor: ${({ isRowClickable }) => (isRowClickable ? 'pointer' : 'default')};

            &-primary {
              & > td > div {
                color: #4cf394;
              }
            }

            &::after {
              content: '';
              position: absolute;
              bottom: -12px;
              left: -12px;
              width: 10px;
              height: 10px;
              border-color: transparent transparent ${({ theme }) => theme.color.darkBlue} transparent;
              transform: rotate(225deg);
              border-width: 12px 12px 12px 12px;
              border-style: solid;
            }

            .table-data-empty {
              position: relative;
              background-color: ${({ theme }) => theme.color.detailEmptyColor};
              vertical-align: middle;
              text-align: center;
              font-size: 20px;
              line-height: 20px;
              color: ${({ theme }) => theme.color.detailEmptyBorder};
              border: 2px solid ${({ theme }) => theme.color.detailEmptyBorder};

              .btn-select-gate {
                width: 100%;
                background: transparent;
                color: #fff;
                border: none;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
              }
            }

            .name {
              width: 250px;
            }
            .table-data.horse_name {
              text-transform: capitalize;
            }

            .table-data {
              vertical-align: middle;
              color: ${({ theme }) => theme.color.white};
              font-size: 18px;
              line-height: 20px;
              border: none;
              padding: 0.5rem 0.3rem;
              &.waiting.cancel_count_down {
                display: none;
              }
              &.scheduling.cancel_count_down {
                display: none;
              }

              .table-data-container {
                .horse-rank {
                  img {
                    width: 50px;
                  }
                }
                .avatar-box {
                  img {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                  }
                }
                .race-name {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .race__title {
                    /* position: relative; */
                    .content-name-horver {
                      white-space: nowrap;
                      width: 200px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: start;
                      /* top: -20px; */
                    }
                  }
                  
                  .contain__icon {
                    position: relative;
                    width: 30px;
                    height: 30px;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
                .total-prize {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  margin-right: 10px;
                }
                .revert_biru {
                  flex-direction: row-reverse;
                }
                .entry-fee,
                .prize {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                }
              }
              .table-gate-no {
                background: #267e5a;
                width: 30px;
                height: 30px;
                text-align: center;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                &.no-1 {
                  background: #ffffff;
                  color: #000000;
                }
                &.no-2 {
                  background: #000000;
                  color: #ffffff;
                }
                &.no-3 {
                  background: red;
                  color: #ffffff;
                }
                &.no-4 {
                  background: #3366ff;
                  color: #ffffff;
                }
                &.no-5 {
                  background: yellow;
                  color: #000000;
                }
                &.no-6 {
                  background: yellow;
                  color: #000000;
                }
                &.no-7 {
                  background: green;
                  color: #ffffff;
                }
                &.no-8 {
                  background: green;
                  color: #ffffff;
                }
                &.no-9 {
                  background: #ff8c00;
                  color: #ffffff;
                }
                &.no-10 {
                  background: #ff8c00;
                  color: #ffffff;
                }
                &.no-11 {
                  background: #ff33cc;
                  color: #ffffff;
                }
                &.no-12 {
                  background: #ff33cc;
                  color: #ffffff;
                }
              }

              .unit {
                font-size: 12px;
              }

              .starts-in {
                column-gap: 8px;

                .dot {
                  width: 8px;
                  height: 8px;
                  border-radius: 20px;

                  background-color: ${({ theme }) => theme.color.red};
                }

                .live-text {
                  font-size: 16px;
                  line-height: 20px;
                }
              }

              .register-btn {
                background-color: ${({ theme }) => theme.color.transparent};
                background-image: url(${OPEN_RACE_BTN_BORDER});
                background-repeat: no-repeat;
                background-size: contain;
                border: none;

                width: 110px;
                height: calc(110px / ${enterBtnDimensionRatio});

                font-size: 16px;

                ${({ theme }) => theme.media.lessThan(theme.size.xl)} {
                  width: 90px;
                  height: calc(90px / ${enterBtnDimensionRatio});

                  font-size: 12px;
                }

                ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
                  width: 110px;
                  height: calc(110px / ${enterBtnDimensionRatio});

                  font-size: 16px;
                }

                ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
                  width: 140px;
                  height: calc(140px / ${enterBtnDimensionRatio});
                }
              }

              .horse-avatar {
                width: 50px;
              }
            }

            .btn-disable-ct {
              /* display: none; */
            }
          }

          .highlight-class {
            background-color: gray;
          }
        }

        .cancel-count-down {
          &.cancel tr {
            color: red;
          }
        }
        .count-down-btns {
          button {
            background: transparent;
            border: 0;
            padding: 0;
            &.btn-waiting-cancel {
              position: relative;
              span {
                position: absolute;
                font-size: 14px;
                right: 8px;
                top: 51%;
                transform: translateY(-50%);
                &.color-yellow {
                  color: #fff566 !important;
                }
              }
            }
          }

          .highlight-class {
            background-color: gray;
          }
        }
        .icon-lock {
          position: absolute;
          right: 16px;
          /* display: none; */
        }
        /* td:last-child .icon-lock {
          display: block;
        } */
        /* td > div {
          padding: 0.3rem 0.5rem;
        } */
        .register {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: end;
          padding-right: 22px;
        }
      }

      .infinite-scroll-component  {
        overflow: unset !important;
      }
    }
    .kuda {
      width: 20px;
      height: 20px;
      margin-top: -3px;
      margin-left: -2px;
    }

    .game-token-merah {
      width: 20px;
      height: 20px;
      margin-top: -3px;
      margin-left: 3px;
    }

    .game-token-biru {
      width: 20px;
      height: 20px;
      margin-top: -3px;
      margin-left: 3px;
    }

    .icon__old {
      opacity: 0.5;
    }

    .first-item .content-name-horver {
      margin: 0 12px;
    }

    .horseInfo {
      width: 300px;
      .cut-text {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 190px;
        white-space: nowrap;
      }
    }
    .career {
      min-width: 100px;
    }
    .count_down {
      width: 170px;
    }
    .owner_name {
      min-width: 200px;
    }
    .race_position {
      min-width: 65px;
    }
    .cut-text,
    .guildName span,
    .guildMaster span,
    .raceName span {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 200px;
      white-space: nowrap;
    }
    .raceName {
      min-width: 150px;
      span {
        max-width: 250px;
      }
    }
    .color-hightlight {
      color: #4cf394;
    }

    .row-name {
      display: flex;
      align-items: center;

      .icon-highlight {
        width: 25px;
      }
    }

    .icon-highlight {
      width: 25px;
    }

    .icon-weather{
      width: 32px;
    }
    /* .icon-weather[src=""], .icon-weather:not([src]){
      opacity: 0;
    } */

    .icon-weather {
      img[src=""], img:not([src]){
        opacity: 0;
      }

      .img[src="*"]{
        opacity: 1;
      }
    }

    .padding__r {
      padding-right: 5px;
    }

    .visibility__coin {
      visibility: hidden;
    }
  }
  .display__flex {
    display: flex;
  }

  .img__icon {
    width: 40px;
    display: flex;
    align-items: center;
  }

  .img_new_record {
    width: 40px;
    height: auto;
    padding: 2px;
    padding-right: 8px;
  }

  .region {
    width: 30px;
    height: 30px;
  }

  .above_region {
    width: 18px;
    height: 18px;
  }

  .contain_img {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;

    div {
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }

  .ct_sub_avata {
    width: 50px;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .horse__name__box {
    display: flex;
    flex-direction: column;
    font-weight: 600;

    .horse__name__title {
      display: flex;
    }
  }

  .box_tokyo {
    font-weight: 600;
    background-color: #ff1f6a;
    width: 80px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
  }

  .box_seoul {
    font-weight: 600;
    background-color: #1ecfcf;
    width: 80px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
  }

  .box_galaxy_road {
    font-weight: 600;
    background-color: #e6a01f;
    width: 120px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
  }

  .jackpot_content {
    width: 55px;
    text-align: center;
  }

  .mg_left_8 {
    margin-left: 8px;
  }

  .ct_armor_skin {
    max-width: 170px;
  }

  .horse-gender-open {
    width: 35px;
  }

  .image_bloodline {
    height: 50px;
    cursor: pointer;
  }

  .mg__left {
    margin-left: 5px;
  }

  .mg__right_5 {
    margin-right: 5px;
  }

  .pd__rigth_3 {
    padding-right: 3px;
  }

  .padding_left_8 {
    padding-left: 8px;
  }

  .btn_disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
`

export default RaceTableStyled
