/* eslint-disable @typescript-eslint/no-explicit-any */
import { Career, Gate, HorseInGate, Race } from 'models'
import { useEffect, useState } from 'react'
import { shortenRaceCourseNameClone } from 'utils/helper'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import { NEW_RECORD_WIN, WIN_JACKPOT_ANIM } from 'assets/images'
import InforHorseWinnerModalStyled from './styled'

interface ChooseHorseItemProps {
  infoWinner?: Gate
  raceDetail?: Race
  jackpot?: any
  newRecord?: any
}

export default function InforHorseWinnerModal({ infoWinner, jackpot = false, newRecord = false }: ChooseHorseItemProps) {
  const { horse } = infoWinner as Gate
  const { sub_avatar, name, user, career } = horse as HorseInGate
  const [winRate, setWinRate] = useState<string>('')
  const [carrer, setCarrer] = useState<string>('')

  const handleCareer = (career: Career) => {
    const totalRace = career?.total_number_of_races + 1
    const firstClass = career?.first_count + 1
    const secondClass = career?.second_count
    const thirdClass = career?.third_count
    const tltClass = (firstClass + secondClass + thirdClass) / totalRace
    const newTltClass = isNaN(tltClass) ? 0 : tltClass * 100
    const totalStatistic = `${totalRace} ${firstClass}/${secondClass}/${thirdClass}`
    const winRateResult = `${Number.isInteger((firstClass / totalRace) * 100)
      ? (firstClass / totalRace) * 100
      : ((firstClass / totalRace) * 100).toFixed(2)
      }% - ${Number.isInteger(newTltClass) ? newTltClass : newTltClass.toFixed(2)}%`
    const handleWinRate = () => {
      let valueDisplay = ''
      if (totalRace === 0) {
        valueDisplay = '---'
      }

      if (totalRace > 0 && firstClass === 0 && secondClass === 0 && thirdClass === 0) {
        valueDisplay = '0.00% - 0.00%'
      }

      if (totalRace !== 0 && (firstClass !== 0 || secondClass !== 0 || thirdClass !== 0)) {
        valueDisplay = winRateResult
      }

      return valueDisplay
    }
    setCarrer(totalStatistic)
    setWinRate(handleWinRate())
  }
  useEffect(() => {
    handleCareer(career)
  }, [])  

  return (
    <InforHorseWinnerModalStyled>
      <div className='winner-container'>
        <div className='winner-background'>
          <div className='winner-top'>
            <img src={sub_avatar} alt='avatar' className='avatar' />
            <div className='avatar-horse'></div>
          </div>
          <div className='infor-horse'>
            <div className='name'> {`${horse?.guild ? `[${horse.guild.guild_tag}]` : ''}`} {name}</div>
            <div className='info'>
              <div className='lable'>
                <div className='owner'>Owner</div>
                <div className='win-rate'>Win Rate</div>
                <div className='career'>Career</div>
              </div>
              <div className='infor-horse-infor'>
                <div className='owner'>
                  <div className='text-ellipsis'>{`${horse?.user?.guild_tag ? `[${horse?.user?.guild_tag}]` : ''}`} {shortenRaceCourseNameClone(user?.name)}</div>
                </div>
                <div className='win-rate'>{winRate}</div>
                <div className='career'>
                  <span className='total'>{carrer.split(' ').shift()}</span>
                  <span className='color-white'>{` - ${carrer.split(' ').pop()}`}</span>
                </div>
              </div>
            </div>
            <div className='box_win_jackpot'>
              {jackpot ? <div className='img_win_jackpot'>
                <MemoizedLoadImageCommon srcImg={WIN_JACKPOT_ANIM} />
              </div> : ""}

              {/* new record */}
              {newRecord ? (
                <div className='img_new_record'>
                  <MemoizedLoadImageCommon srcImg={NEW_RECORD_WIN} />
                </div>
              ) : ""}
            </div>
          </div>
        </div>
      </div>
    </InforHorseWinnerModalStyled>
  )
}
