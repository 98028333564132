/* eslint-disable @typescript-eslint/no-explicit-any */
import { links } from 'apps'
import { notification } from 'antd'
import constants, { SPECTRE_RPC } from 'apps/constants'
import ModalCancelRace from 'features/ModalCancelRace'
import { ResultHorseModal } from 'features/Race/components'
import { useFocusTopScreen, useReloadCurrentPage, useToggle } from 'hooks'
import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { useNavigate } from 'react-router-dom'
import { Footer, Header } from 'shared'
import { removeDataAtLocalStorage, shortenRaceNameV2 } from 'utils/helper'
import { WS_MANAGER } from 'socket/socketClient'
import CommonLayoutStyled from './styled'
import { convertShortTimeMinus } from 'utils/time'

interface CommonLayoutProps {
  children: React.ElementType
}

let arrayNotiNewRecord: any[] = []
let arrayNoti: any[] = []

function CommonLayout({ children }: CommonLayoutProps) {
  useFocusTopScreen()
  const [isModalResultHorseOpen, toggleIsModalResultHorseOpen] = useToggle(false)
  const [titleBalace, setTitleBalance] = useState<string>('')

  const transaction = localStorage.getItem('transaction') as any
  const messageSuccess = `Congratulation!
  You have successfully balance transfered.`
  const navigate = useNavigate()

  useEffect(() => {
    if ((window as any).chrome) {
      window.ethereum?.on('chainChanged', function (networkId: string) {
        if (networkId !== SPECTRE_RPC) {
          removeDataAtLocalStorage()
          useReloadCurrentPage()
          navigate(links.home.index())
        }
      })

      window.ethereum?.on('accountsChanged', async () => {
        if (localStorage?.getItem(constants.ACCESS_TOKEN_KEY) != 'null') {
          removeDataAtLocalStorage()
          useReloadCurrentPage()
          navigate(links.home.index())
        }
      })
    }
  })

  const handleSocketJackpot = async (message: { body: string }) => {
    const data = JSON.parse(message.body)
    for (let i = 0; i < data.length; i++) {
      const ms = i > 0 ? 3200 : 0
      await delay(data[i], ms)
    }
  }

  const delay = async (data: any, ms: any) => {
    await new Promise((resolve) => {
      setTimeout(() => {
        pushNotification(data?.horseName, data?.ownerName, data?.raceName)
        resolve(null)
      }, ms)
    })
  }

  const pushNotification = (horseName: string, ownerName: string, raceName: string) => {
    const notificationKey = `${horseName}-${ownerName}-${raceName}`;
    if (arrayNoti?.includes(notificationKey)) {
      notification.destroy()
    } else {
      arrayNoti?.push(notificationKey)
    }
    notification.success({
      message: '',
      description: (
        <div style={{ wordBreak: "break-word" }}>
          <span style={{ color: "#e62e2e" }}>{horseName}</span> of {``}
          <span style={{ color: "#25D8FD" }}>{shortenRaceNameV2(ownerName)}</span> has got jackpot prize in {`"`}
          <span style={{ color: "#ffc700" }}>{raceName}</span>{`"`} race
        </div>
      ),
      placement: 'bottomRight',
      duration: 3,
      className: 'ant_custom_success'
    })
  }

  const handleSocketNewRecord = async (message: { body: string }) => {
    const data = JSON.parse(message.body)
    for (let i = 0; i < data.length; i++) {
      const ms = i > 0 ? 3200 : 0
      await delayNewRecord(data[i], ms)
    }
  }

  const delayNewRecord = async (data: any, ms: any) => {
    await new Promise((resolve) => {
      setTimeout(() => {
        pushNotificationNewRecord(data)
        resolve(null)
      }, ms)
    })
  }

  const pushNotificationNewRecord = (data: any) => {
    const notificationKey = `${data?.horseName}-${data?.ownerName}-${data?.raceName}-${data?.raceClass}-${data?.raceField}-${data?.raceDistance}-${data?.recordTime}`;
    if (arrayNotiNewRecord?.includes(notificationKey)) {
      notification.destroy()
    } else {
      arrayNotiNewRecord?.push(notificationKey)
    }
    notification.success({
      message: '',
      description: (
        <div style={{ wordBreak: "break-word" }}>
          <div>
            <span style={{ color: "#e62e2e" }}>{data?.horseName}</span>{`,`} owned by {``}
            <span style={{ color: "#25D8FD" }}>{shortenRaceNameV2(data?.ownerName)}</span>{`,`} set a new record in the {`"`}
            <span style={{ color: "#ffc700" }}>{data?.raceName}</span>{`"`} race
          </div>
          <div style={{ height: "15px" }}></div>
          <div>
            <span>Race type: {``}</span>
            <span style={{ textTransform: "uppercase" }}>{data?.raceClass}</span> {` - `}
            <span style={{ textTransform: "uppercase" }}>{data?.raceField}</span> {` - `}
            <span>{data?.raceDistance}{` m`}</span>
          </div>
          <div style={{ height: "15px" }}></div>
          <div>
            <span>Record time: {convertShortTimeMinus(data?.recordTime)}</span>
          </div>
        </div>
      ),
      placement: 'bottomRight',
      duration: 3,
      className: 'ant_custom_success'
    })
  }

  useEffect(() => {
    const subscriptionNewRecord = WS_MANAGER.subscribe('/topic/new-record', handleSocketNewRecord)
    const subscriptionJackpot = WS_MANAGER.subscribe('/topic/jackpots', handleSocketJackpot)

    return () => {
      subscriptionNewRecord?.then(sub => sub?.unsubscribe())
      subscriptionJackpot?.then(sub => sub?.unsubscribe())
      arrayNotiNewRecord = []
      arrayNoti = []
    }
  }, [])

  useEffect(() => {
    const jsonTransaction = JSON.parse(transaction)
    const handleWaitForTransaction = async () => {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        if (jsonTransaction && jsonTransaction !== null) {
          try {
            const waitTransaction = await provider.waitForTransaction(jsonTransaction.hash)
            if (waitTransaction) {
              toggleIsModalResultHorseOpen(true)
              setTitleBalance('success!')
            }
          } catch (err) {
            console.log(err)
          }
        }
      }
    }
    handleWaitForTransaction()
  })

  const handleOk = () => {
    localStorage.removeItem(constants.TRANSACTION)
    useReloadCurrentPage()
    toggleIsModalResultHorseOpen(false)
  }

  return (
    <CommonLayoutStyled>
      <div className='header-container position-fixed w-100'>
        <Header />
      </div>
      <div className='content-container'>{children}</div>
      <div className='footer-container'>
        <Footer />
      </div>
      <ModalCancelRace />
      {isModalResultHorseOpen && (
        <ResultHorseModal title={titleBalace} onOk={handleOk} message={messageSuccess} exchangeCoin={true} />
      )}

    </CommonLayoutStyled>
  )
}

export default CommonLayout
