import styled from 'styled-components'

interface ProfileCloneStyledProp {
  total?: number
}

const NewRecordDetailStyled = styled.div<ProfileCloneStyledProp>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 12px;

  .title_new_record {
    display: flex;
    align-items: center;
    margin-top: 16px;
    width: 100%;

    .title {
      font-size: 24px;
      color: #4EF076;
      text-transform: uppercase;
      text-decoration: unset;
      &.active, &:hover{
        position: relative;
        img {
          position: absolute;
          bottom: -7px;
          left: 0;
          width: 109px;
        }
      }
    }
  }

  .new-record-container {
    background-color: #121520;
    padding: 32px 0px;
    text-align: left;
    width: 100%;

    ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
      padding: 32px;
    }

    ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
      padding: 16px 12px;
    }
    ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
      overflow-x: scroll;
    }

    .header-horse {
      font-size: 16px;
      font-weight: 600;
      color: #d9d9d9;
    }

    .new-record-table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 12px;

      ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
        min-width: 760px;
      }

      .no_data {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 0;
        background-color: #191d2c;
        clip-path: polygon(100% 0, 100% 100%, 22px 100%, 0 calc(60px - 22px), 0 0);
      }

      .new_record_body {
        background: linear-gradient(90deg, rgba(213, 153, 0, 1) 0%, rgba(213, 153, 0, 1) 0%, rgba(43, 32, 1, 1) 100%);
        clip-path: polygon(100% 0, 100% 100%, 22px 100%, 0 calc(60px - 22px), 0 0);
        cursor: pointer;

        td {
          text-align: left;
          vertical-align: middle;
          padding: 12px 0;
          font-size: 16px;
          line-height: 20px;
        }

        .race_name {
          max-width: 200px;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          padding-right: 10px;
          white-space: nowrap;
        }

        .race_bold {
          font-weight: 600;
        }
      }

      .region {
        display: block;
        width: 30px;
        height: 30px;
        margin-left: 0;
      }
    }

    .margin-left-11 {
      margin-left: 11px;
    }

    .box_tokyo {
      font-weight: 600;
      background-color: #ff1f6a;
      width: 80px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1px;
    }

    .box_seoul {
      font-weight: 600;
      background-color: #1ecfcf;
      width: 80px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1px;
    }

    .width-5 {
      width: 5%  !important;
    }

    .width-4 {
      width: 4%  !important;
    }

    .width-8 {
      width: 8%  !important;
    }

    .width-12 {
      width: 12%  !important;
    }

    .width-13 {
      width: 13%  !important;
    }

    .width-10 {
      width: 10%  !important;
    }
    .width-15 {
      width: 15% !important;
    }

    .width-16 {
      width: 16% !important;
    }

    .width-17 {
      width: 17%  !important;
    }

    .width-18 {
      width: 18%  !important;
    }

    .width-19 {
      width: 19%  !important;
    }

    .width-20 {
      width: 20%  !important;
    }

    .width-21 {
      width: 21%  !important;
    }

    .width-22 {
      width: 22%  !important;
    }

    .width-27 {
      width: 27%  !important;
    }

    .width-25 {
      width: 25%  !important;
    }

    .paginate {
      display: flex;
      justify-content: center;
      .pagination {
        .page-item {
          .page-link {
            z-index: 3;
            color: #fff;
            background-color: #191d2c;
            border-color: #51c21a;
            &:focus {
              box-shadow: none;
            }
          }
        }

        .page-item.active {
          .page-link {
            z-index: 3;
            color: #fff;
            background-color: #135200;
            border-color: #135200;
            font-weight: 700;
          }
        }

        .page-item.disabled {
          cursor: not-allowed;
          .page-link {
            z-index: 3;
            color: #fff;
            background-color: #191d2c;
            border-color: #191d2c;
          }
        }

        .page-item:hover {
          .page-link {
            z-index: 3;
            color: #fff;
            background-color: #135200;
            border-color: #191d2c;
          }
        }
      }
    }
  }
  .img_new_record {
    width: 30px;
    height: auto;
    margin-left: 30px;
  }
`

export default NewRecordDetailStyled
