import styled from 'styled-components'

const CurrentRecordHolderStyle = styled.div`
  margin: 20px 0;
  vertical-align: middle;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(43, 32, 1, 1) 0%, rgba(213, 153, 0, 1) 50%, rgba(43, 32, 1, 1) 100%);
  height: 50px;
  line-height: 50px;
  /* padding: 5px 0; */

  .name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    margin-right: 40px;

    .img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      margin-top: -3px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .text_record_holder {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 50px;
    text-align: end;
    padding-right: 40px;
  }

  .text_holder {
    font-size: 20px;
    font-weight: 600;
  }

  .text_holder_green {
    color: #4cf394;
  }

  .left {
    color: #fff;
    text-align: left;
    /* padding-left: 105px; */

    span {
      color: #fff;
      line-height: 40px;
    }
  }

  .width-100 {
    width: 100%;
  }

  .width-45 {
    width: 45%;
  }
`

export default CurrentRecordHolderStyle
